import React from 'react';

export const Input = ({ type, name, errors, register, rules, ...props }) => (
  <input
    id={`${name}__field`}
    type={type ?? 'text'}
    className="w-full h-14 rounded-xl border border-solid px-6 py-4 border-neutral-500/25 text-neutral-900"
    aria-invalid={errors[name] ? 'true' : 'false'}
    {...props}
    {...register(name, rules)}
  />
);
