import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { Loader } from '~/components/Loader';
import { useApi } from '~/hooks/useApi';

import DeleteIcon from './assets/DeleteIcon.svg?react';
import EditIcon from './assets/EditIcon.svg?react';

const NEW_PROJECT = {
  id: '__new__',
  name: 'New project',
}

export const ProjectsPage = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState([NEW_PROJECT]);
  const [isLoading, setIsLoading] = useState(false);
  const apiRequest = useApi();

  useEffect(() => {
    setIsLoading(true);
    apiRequest({url: '/project/' }).then(({ success, body: projects }) => {
      if (success) {
        setProjects([NEW_PROJECT].concat(projects));
      }
      setIsLoading(false);
    });
  }, []);

  const onClickEdit = (e, id) => {
    e.stopPropagation();
    navigate(ROUTES.PROJECT.replace(':id', id));
  };

  const onClickDelete = (e, id) => {
    e.stopPropagation();
    navigate(ROUTES.PROJECT_DELETE.replace(':id', id));
  }

  const onClick = (id) => {
    if (id === '__new__') {
      navigate(ROUTES.PROJECT_NEW);
    } else {
      navigate(ROUTES.PROJECT_ACTIVITIES.replace(':id', id));
    }
  };

  return (
    <ModalLikePage title="My projects">

    <div className="w-full gap-6 grid grid-cols-1 md:grid-cols-2">
      {projects.map(({ id, name }) => {
        return (
          <div className="w-full h-44 py-6 flex flex-col text-center text-neutral-600 items-center" key={`project${id}`}>
            {id === '__new__' ? (
              <div
                className="w-24 h-24 mx-10 border border-solid border-neutral-600 rounded-lg flex flex-col justify-center align-center text-6xl font-black cursor-pointer"
                onClick={() => onClick('__new__')}
              >+</div>
            ) : (
              <div
                className="relative w-24 h-24 mx-10 bg-purple-900 rounded-lg flex flex-col justify-center align-center text-5xl text-white font-black cursor-pointer"
                onClick={() => onClick(id)}
              >
                <EditIcon className="absolute -top-6 right-7" onClick={(e)=> onClickEdit(e, id)} />
                <DeleteIcon className="absolute -top-6 right-1" onClick={(e)=> onClickDelete(e, id)} />
                {name?.[0] ?? 'T'}
              </div>
            )}
            <div
              className="text-xl cursor-pointer mt-1"
              onClick={onClick}
            >{name}</div>
          </div>
        );
      })}

      {isLoading && (
        <div className="w-44 h-36 py-6 md:flex md:flex-col text-center justify-center align-center">
          <Loader />
        </div>
      )}

    </div>
    </ModalLikePage>
  );
};
