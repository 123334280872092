import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { StackedGroupedBar } from '~/components/Charts/StackedGroupedBar';
import { useApi } from '~/hooks/useApi';

import { getFilters, mapToStackedGroupedData } from './utils';

export const QuantityDecisionsPerMemberChart = ({
  aggregationPeriod,
  project,
  streams,
  streamsMap,
  height,
}) => {
  const apiRequest = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [shares, setShares] = useState(() => []);

  const filters = getFilters(streams, aggregationPeriod);

  useEffect(() => {
    if (!project.id || !filters.hasFilters) {
      return;
    }

    setIsLoading(true);
    apiRequest({
      url: '/dashboard/quantity-business-decisions-per-team-member' + filters.query,
    }).then(({ success, body }) => {
      if (success) {
        const data = mapToStackedGroupedData(body.periods, streamsMap);
        setShares(data);
      }
      setIsLoading(false);
    });
  }, [project.id, filters.query])

  return (
    <div>
      <ParentSize>
        {({ width }) => (
          <StackedGroupedBar data={shares} width={width} height={height} isLoading={isLoading} />
        )}
      </ParentSize>
    </div>
  );
};
