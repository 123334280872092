import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { apiClient } from '~/api';
import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { useApi } from '~/hooks/useApi';
import { useToast } from '~/hooks/useToast';

import { SettingsTabs } from './SettingsTabs';

export const ProfilePage = () => {
  const {
    control,
    formState: {isSubmitting, isValid, errors},
    handleSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: {
      old_password: '',
      new_password: '',
    }
  });

  const apiRequest = useApi(setError);
  const { show } = useToast();

  const onSubmit = async (data) => {
    const res = await apiRequest({
      url: `/user/change-password`,
      method: 'POST',
      data,
    });

    if (res.success) {
      show('Password has been changed successfully.')
    }
  };

  return (
    <ModalLikePage title="Settings">

      <SettingsTabs activeTab="Profile" />

      <p className="w-full text-left mb-6">
        You can change your current password.
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Current password"
          name="old_password"
          type="password"
          autoComplete="current-password"
          register={register}
          errors={errors}
          minLength={8}
          required
        />

        <FormField
          label="New password"
          name="new_password"
          type="password"
          autoComplete="new-password"
          register={register}
          errors={errors}
          help="Use 8 or more characters with a mix of letters & numbers."
          required="Field is required"
          minLength={8}
        />

        <SubmitButton
          text="Change password"
          isActive={isValid}
          isLoading={isSubmitting}
        />

      </Form>

    </ModalLikePage>
  );
}
