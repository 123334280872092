import React from 'react';
import { Controller } from 'react-hook-form';

import { Switch } from './Switch';

export const Checkbox = ({ name, control, defaultValue, rules, ...props }) => {
  if (!control) {
    return <Switch name={name} {...props} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => <Switch {...field} />}
    />
  );
};
