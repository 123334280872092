export const cutString = (str, maxLength=16, quote=true) => {
  if (!str) {
    return '…';
  }

  if (str?.length < maxLength) {
    return quote ? `«${str}»` : str;
  }

  const cut = str.substring(0, maxLength) + '…';

  return quote ? `«${cut}»` : cut;
};
