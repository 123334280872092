import React from 'react';

import { Button } from '~/components/Button';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { NavLink } from '~/components/NavLink';
import { ROUTES } from '~/routes';

import pic1 from './assets/gc_1.png?url=';
import pic2 from './assets/gc_2.png?url=';
import pic3 from './assets/gc_3.png?url=';
import am from './assets/am.png?url=';
import Quote from './quote.svg?react';


const Feature = ({ heading, text, ...props }) => (
  <div className="flex flex-col items-start gap-2 self-stretch" {...props}>
    <div className="text-3xl font-bold">{heading}</div>
    <div className="text-base">{text}</div>
  </div>
);

const features = [
  {
    heading: 'Schedule',
    text: 'The market is changing and the hypotheses are needed to be revalidated from regularly. Get highlights on outdated decisions.',
  },
  {
    heading: 'Record',
    text: 'The bigger your product the more complex the sequence of decisions it took to come where you are. Tailor the chain of hypotheses to have a panoramic view on the product’s status.',
  },
  {
    heading: 'Compare',
    text: 'The more hypotheses your team validates, the faster your product grow. Compare your product’s speed with other teams. View the distribution.',
  },
];

export const MainPage = () => {
  return (
    <>
      <Header />

      <main>
        <div className="px-20 py-16 bg-neutral-500">
          <h1 className="text-white text-2xl md:text-6xl md:max-w-[1000px] font-semibold md:leading-20">
            Organize your product growth by cultivating data-driven decisions
          </h1>
        </div>

        <div
          className="px-28 py-12 flex items-stretch gap-[32px] text-white md:flex-row flex-col"
          style={{ background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #6B7280' }}
        >
          {features.map((feature, idx) => <Feature key={idx} {...feature} />)}
        </div>

        <div className="px-28 py-24 bg-primary-600 flex items-center gap-3 text-white text-3xl font-light">
          <Quote />
          Our success at Amazon is a function of how many<br />
          experiments we do per year, per month, per week, per day.
          <div className="px-12 gap-4 flex items-center">
            <img src={am} className="w-14 rounded-md" />
            <div>
              <div className="text-lg">Jeff Bezos</div>
              <div className="text-sm">Founder @ Amazon</div>
            </div>
          </div>
        </div>

        <div className="px-28 py-24 flex flex-col md:flex-row items-start justify-stretch gap-8">
          <div className="flex-1 flex flex-col items-start gap-8 px-12">
            <div className="text-5xl mr-12 font-semibold leading-20">
              Data-Driven Decision Consistency
            </div>
            <div className="mr-12 text-lg">
              GrowthClue revolutionizes the way product managers make decisions.
              By meticulously tracking and organizing decision history and backlogs,
              our service ensures that every choice is grounded in solid, experimental data,
              such as A/B testing results. This approach not only preserves valuable insights
              gained during the product development process but also eliminates the costly need
              to revalidate proven hypotheses. With GrowthClue, make each decision a step forward,
              not a step back.
            </div>
          </div>

          <div className="flex-1 px-32">
            <img src={pic1} className="w-full rounded-md" />
          </div>
        </div>

        <div
          className="px-28 py-24 flex flex-col md:flex-row items-start justify-stretch gap-8"
          style={{backgroundColor: 'rgba(192, 218, 229, 0.25)'}}
        >
          <div className="flex-1 flex flex-col items-start gap-8 px-12">
            <div className="text-5xl mr-12 font-semibold leading-20">
              Decision Revalidation Schedule
            </div>
            <div className="mr-12 text-lg">
              In the ever-evolving landscape of product management, staying current is key.
              GrowthClue empowers product managers to identify and reassess outdated decisions,
              ensuring that your product strategy aligns with the latest market trends and user needs.
              Our platform's analytical tools help you to seamlessly pinpoint decisions needing reevaluation,
              fostering a culture of continuous improvement and agility in your product development lifecycle.
            </div>
          </div>

          <div className="flex-1 px-32">
            <img src={pic2} className="w-full rounded-md" />
          </div>
        </div>

        <div className="px-28 py-24 flex flex-col md:flex-row items-start justify-stretch gap-8">
          <div className="flex-1 flex flex-col items-start gap-8 px-12">
            <div className="text-5xl mr-12 font-semibold leading-20">
              Enhance Team Performance with Benchmarking
            </div>
            <div className="mr-12 text-lg">
              GrowthClue elevates the capabilities of product management by enabling insightful
              internal benchmarking within your organization. This powerful feature allows you
              to compare the decision-making pace and effectiveness of different teams, shedding
              light on areas where efficiency can be boosted. By identifying teams that are
              lagging in the product decision-making process, GrowthClue facilitates the sharing
              of successful practices from your top-performing teams. This not only accelerates
              decision-making across the board but also fosters a culture of continuous learning
              and collaboration, driving overall organizational growth and innovation.
            </div>
          </div>

          <div className="flex-1 px-32">
            <img src={pic3} className="w-full rounded-md" />
          </div>
        </div>

        <div className="mt-6 mb-4 flex justify-center">
          <NavLink to={ROUTES.SIGNUP}>
          <Button
                text="Sign Up"
                className="w-[338px] h-[95px] bg-purple-600 text-3xl font-semibold"
              />
          </NavLink>
        </div>
        <div className="mb-20 flex justify-center text-purple-900 text-lg">
          It’s completely free, no card required.
        </div>

      </main>

      <Footer />
    </>
  );
};
