import React from 'react';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { BarGroup } from '@visx/shape';
import { AxisBottom, AxisLeft } from '@visx/axis';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { useTooltip, useTooltipInPortal, defaultStyles } from '@visx/tooltip';
import { localPoint } from '@visx/event';

import { Loader } from '~/components/Loader';
import { numFormatter } from '~/utils/number';

export const background = '#ffffff';
const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: 'rgba(0,0,0,0.9)',
  color: 'white',
};

// accessors
const getX = (d) => d.x;

// const parseDate = timeParse('%Y-%m-%d');
// const format = timeFormat('%b %Y');
// const formatDate = (date) => format(parseDate(date));

let tooltipTimeout;

export const StackedGroupedBar = ({
  data,
  width,
  height,
  events = true,
  margin = { top: 40, right: 0, bottom: 40, left: 40 },
  isLoading,
  formatX,
  formatY,
  bottomAxis = {},
}) => {
  if (!data?.length) {
    return null;
  }

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  let keys, maxYScaleValue;
  if (data?.length) {
    keys = Object.keys(data[0]).filter((d) => d !== 'x');
    maxYScaleValue = Math.max(
      ...data.map(
        (d) => Math.max(
          ...keys.map(
            (key) => {
              return Math.max(...d[key].map(i => i.value))
            }
          )
        )
      )
    );
  }

  if (!data.length || isLoading || maxYScaleValue === -Infinity) {
    return (
      <div style={{ height: `${height}px` }} className="w-full flex items-center justify-center">
        {isLoading ? (<Loader />) : 'No data'}
      </div>
    );
  }

  // scales
  const x0Scale = scaleBand({
    domain: data.map(getX),
    padding: 0.2,
  });

  const x1Scale = scaleBand({
    domain: keys,
    padding: 0.1,
  });
  const yScale = scaleLinear({
    domain: [0, maxYScaleValue],
    nice: true,
  });

  // console.log(data[0])
  const colorScale = scaleOrdinal({
    domain: keys.map(k => `${k}: ${data[0][k][0].name}`),
    range: keys.map(k => data[0][k][0].color),
  });

  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // update scale output dimensions
  x0Scale.rangeRound([0, xMax]);
  x1Scale.rangeRound([0, x0Scale.bandwidth()]);
  yScale.range([yMax, 0]);

  if (width < 10) return null;

  const factor = yScale.range()[0] / yScale.domain()[1];
  const leftAxisMaxValue = yScale.domain()[1];
  const leftAxisNumTick = leftAxisMaxValue <= 2 ? 4 : (
    leftAxisMaxValue < 10 ? leftAxisMaxValue : Math.ceil(leftAxisMaxValue / 10)
  );

  return (
    <div>
      <svg width={width} height={height}>
        <rect x={0} y={0} width={width} height={height} fill={background} rx={14} />
        <GridRows
            top={margin.top}
            left={margin.left}
            // xScale={x0Scale}
            scale={yScale}
            width={xMax}
            height={yMax}
            stroke="black"
            strokeOpacity={0.1}
            // xOffset={x0Scale.bandwidth() / 2}
          />
        <Group top={margin.top} left={margin.left}>
          <BarGroup
            data={data}
            keys={keys}
            height={yMax}
            x0={getX}
            x0Scale={x0Scale}
            x1Scale={x1Scale}
            yScale={yScale}
            color={colorScale}
          >
            {(barGroups) =>
              barGroups.map((barGroup) => (
                <Group key={`bar-group-${barGroup.index}-${barGroup.x0}`} left={barGroup.x0}>
                  {barGroup.bars.map((bar) => bar.value.map((stack, i) => {
                    // console.log(barGroup, bar, stack, tempScale.range()[0], tempScale.domain()[1])
                    const y = yMax - factor * stack.value;
                    return (
                      <rect
                        key={`bar-group-bar-${barGroup.index}-${bar.index}-${bar.value}-${bar.key}-${i}`}
                        x={bar.x}
                        y={y}
                        width={bar.width}
                        height={factor * stack.value}
                        opacity={stack.opacity ?? 1}
                        fill={stack.color}
                        z={100 - i}
                        rx={4}
                        onClick={(event) => {
                          if (!events) return;
                          const { key, value } = bar;
                          const eventSvgCoords = localPoint(event);
                          console.log(event)
                          alert(JSON.stringify({ key, value, eventSvgCoords }));
                        }}
                        onMouseLeave={() => {
                          tooltipTimeout = window.setTimeout(() => {
                            hideTooltip();
                          }, 300);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout) clearTimeout(tooltipTimeout);
                          // TooltipInPortal expects coordinates to be relative to containerRef
                          // localPoint returns coordinates relative to the nearest SVG, which
                          // is what containerRef is set to in this example.
                          const eventSvgCoords = localPoint(event);
                          const left = barGroup.x0 + bar.x + bar.width / 2;
                          // console.log(bar, stack)
                          showTooltip({
                            tooltipData: {bar, stack},
                            tooltipTop: event.clientY,
                            tooltipLeft: event.clientX,
                          });
                        }}
                      />
                    )
                  }))}
                </Group>
              ))
            }
          </BarGroup>
        </Group>

        <AxisBottom
          top={yMax + margin.top}
          tickFormat={formatX}
          numTicks={bottomAxis.numTicks}
          left={bottomAxis.left ?? 0}
          scale={x0Scale}
          // stroke="black"
          // strokeOpacity={0.1}
          stroke={'rgba(0, 0, 0, 0.1)'}
          tickStroke={'rgba(0, 0, 0, 0.1)'}
          hideAxisLine
          tickLabelProps={{
            fill: 'rgba(0, 0, 0, 0.5)',
            fontSize: 11,
            textAnchor: 'middle',
          }}
        />

        <AxisLeft
          left={margin.left}
          top={margin.top}
          hideZero
          numTicks={leftAxisNumTick}
          hideAxisLine
          // hideTicks
          scale={yScale}
          tickFormat={formatY}
          stroke={'rgba(0, 0, 0, 0.1)'}
          tickStroke={'rgba(0, 0, 0, 0.1)'}
          tickLabelProps={{
            fill: 'rgba(0, 0, 0, 0.5)',
            fontSize: 11,
            textAnchor: 'end',
            dy: '0.33em',
          }}
        />
      </svg>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal top={tooltipTop} left={tooltipLeft} style={tooltipStyles}>
          <div style={{ color: tooltipData.stack.color }} key="t">
            <strong>{tooltipData.bar.key}</strong>
          </div>
          {tooltipData.bar.value.map((item, i) => (
            <div key={i}>{item.name}: {numFormatter.format(item.value)}</div>
          ))}
        </TooltipInPortal>
      )}

      <div
        style={{
          // position: 'absolute',
          //top: margin.top / 2 - 10,
          // width: '100%',
          // display: 'flex',
          // justifyContent: 'space-between',
          flexWrap: 'wrap',
          fontSize: '14px',
        }}
        className="w-100 flex justify-between gap-2 m-2"
      >
        {/* <LegendOrdinal scale={colorScale} direction="row"  labelMargin="0 15px 0 0" /> */}
        {keys.map((key, i) => {
          return (
            <div className="flex flex-col" key={`g-${i}`}>
              {data[0][key].map((item, i) => (
                <div className="mb-2 truncate overflow-hidden text-nowrap" key={`s-${i}`} style={{ maxWidth: '250px' }}>
                  <span
                    className="inline-block align-middle mx-2 w-5 h-5 rounded"
                    style={{ backgroundColor: item.color, opacity: item.opacity ?? 1}}
                  ></span>
                  {key}: {item.name}
                </div>
              ))}
            </div>
          );
        })}
      </div>

    </div>
  );
}
