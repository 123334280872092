import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { GroupedBar } from '~/components/Charts/GroupedBar';
import { useApi } from '~/hooks/useApi';

import { getFilters } from './utils';


export const DistributionSharesValidatedDecisionsChart = ({
  project,
  streams,
  streamsMap,
  height,
}) => {
  const apiRequest = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(() => []);

  const filters = getFilters(streams, null, project.id);
  console.log(filters)

  useEffect(() => {
    if (!project.id || !filters.hasFilters) {
      return;
    }

    setIsLoading(true);
    apiRequest({
      url: '/dashboard/distribution-shares-validated-decisions' + filters.query,
    }).then(({ success, body }) => {
      if (success) {
        const distribution = [];
        const {
          by_project: {
            validated_decisions: validatedProject,
          },
          by_industry: {
            validated_decisions: validatedIndustry,
          }
        } = body ?? {};

        for (let key of Object.keys(validatedProject)) {
          distribution.push({
            // key is 'bucket_0_5'
            x: key.split('_')[2] + '%',
            'Share of validated decisions in the project': { value: validatedProject[key].distribution, color: '#8C30F5' },
            'Share of validated decisions in the industry': { value: validatedIndustry[key].distribution, color: '#1DB5BE' },
          });
        }
      
        setData(distribution);
      }

      setIsLoading(false);
    });
  }, [project.id, filters.streamIds.join(',')])

  return (
    <div>
      <ParentSize>
        {({ width }) => (
          <GroupedBar
            data={data}
            width={width}
            height={height}
            isLoading={isLoading}
            formatY={(v) => `${v}%`}
            bottomAxis={{ left: 20, numTicks: 20 }}
          />
        )}
      </ParentSize>
    </div>
  );
};
