import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { GroupedBar } from '~/components/Charts/GroupedBar';
import { useApi } from '~/hooks/useApi';

import { getFilters, mapToGroupedData } from './utils';


export const TasksToBeDoneChart = ({
  aggregationPeriod,
  height,
  project,
  streams,
  streamsMap,
}) => {
  const apiRequest = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(() => []);

  const filters = getFilters(streams, aggregationPeriod);

  useEffect(() => {
    if (!project.id || !filters.hasFilters) {
      return;
    }

    apiRequest({
      url: '/dashboard/quantity-validation-tasks-per-team' + filters.query,
    }).then(({ success, body }) => {
      if (success) {
        const data = mapToGroupedData(body.periods, streamsMap);
        setData(data);
      }
      setIsLoading(false);
    });
  }, [project.id, filters.query]);

  return (
    <div>
      <ParentSize>
        {({ width }) => (
          <GroupedBar data={data} width={width} height={height} isLoading={isLoading} />
        )}
      </ParentSize>
    </div>
  );
};
