export class ApiClient {
  url = '/api/v1';
  accessToken = null;

  constructor() {
  }

  async request({ url, method, headers, data, query, ...args }, displayErrors = true) {
    if (!headers) {
      headers = { 'Content-Type': 'application/json' };
    } else if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    }
    if (this.accessToken) {
      headers.Authorization = 'Bearer ' + this.accessToken;
    }

    if (query) {
      url += (url.includes('?') ? '&' : '?') + new URLSearchParams(query).toString();
    }

    let resp;
    try {
      resp = await fetch(this.url + url, {
        method: method ?? 'GET',
        headers,
        body: data ? JSON.stringify(data) : null,
        ...args,
      });
    } catch (err) {
      console.log(err);
    }

    let body;
    try {
      body = await resp.json();
    } catch (err) {
      console.error(err);
    }

    if (resp.status >= 200 && resp.status < 300) {
      return {
        success: true,
        body: body ?? {}
      };
    } else {
      return {
        success: false,
        status: resp.status,
        body: body ?? {}
      };
    }
  }
}

export const apiClient = new ApiClient();
