import React from 'react';
import { useLocation } from 'react-router-dom';

import { BaseTree } from '~/components/Tree';
import { FormField } from '~/components/Form/FormField';

const menu = [
  { hash: '#how-fast', title: 'How fast are we going', },
  { hash: '#where', title: 'Where are we going', },
  { hash: '#how-qualitative', title: 'How qualitative are our decisions', },
];

const aggregationPeriods = [
  { value: 'week', label: '1 week' },
  { value: 'two_week', label: '2 weeks' },
  { value: 'month', label: '1 month' },
  { value: 'quarter_year', label: '3 months' },
  { value: 'half_year', label: '6 months' },
  { value: 'year', label: '1 year' },
];

export const ProjectDashboardMenu = ({
  aggregationPeriod,
  setAggregationPeriod,
  setStreams,
  streams,
}) => {
  const { hash } = useLocation();

  if (!streams) {
    return;
  }

  return (
    <div className="sticky top-0">
      <h2 className="text-3xl font-medium mb-7">Navigation</h2>

      <ul className="text-purple-600 text-xl mb-11">
        {menu.map((item, i) => (
          <React.Fragment key={i}>
            {(!hash && !i) || item.hash === hash ? (
              <li className="text-purple-600">{item.title}</li>
            ) : (
              <li><a className="text-purple-600/50" href={item.hash}>{item.title}</a></li>
            )}
          </React.Fragment>
        ))}
      </ul>

      <h3 className="text-3xl font-normal mb-4">Filters</h3>

      <BaseTree
        label="Inspecting data"
        nodes={streams}
        onSelect={(node, value) => {
          setStreams((prev) => {
            prev[node.idx].selected = value;

            // Toggle all child nodes:
            for (let i = node.idx + 1, depth = node.depth; i < prev.length && prev[i].depth > depth; i++) {
              prev[i].selected = value;
            }

            return prev.slice();
          });
        }}
      />

      <FormField
        label="Jump to date"
        name="date"
        type="datePicker"
        className="mt-4"
      />

      <FormField
        label="Aggregation period"
        name="aggregationPeriod"
        type="select"
        options={aggregationPeriods}
        className="mt-4"
        onChange={(elem) => setAggregationPeriod(elem.value)}
        value={aggregationPeriods.find(({ value }) => value === aggregationPeriod)}
        required
      />
    </div>
  );
};
