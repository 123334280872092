import React from 'react';
import { ScrollRestoration } from 'react-router-dom';

import { CookieBanner } from '~/components/CookieBanner';
import { NavLink } from '~/components/NavLink';
import { ROUTES } from '~/routes';
import { useAuth } from '~/hooks/useAuth';

import Logo from './logo.svg?react';

const LINKS = [
  { to: ROUTES.HOME, text: 'About' },
  { to: ROUTES.LOGIN, text: 'Log In', noAuth: true },
  { to: ROUTES.SIGNUP, text: 'Sign Up', noAuth: true },
  { to: ROUTES.PROJECTS, text: 'My projects', auth: true },
  { to: ROUTES.PRIVACY, text: 'Privacy Policy' },
  { to: ROUTES.TERMS, text: 'Terms of Service' },
  { to: ROUTES.CONTACTS, text: 'Contacts' },
];

export const Footer = () => {
  const { user } = useAuth();

  return (
    <footer className="bg-black px-12 md:px-28 py-4 md:py-16 md:flex md:flex-col items-start gap-1 md:gap-6 text-white">
      <Logo />

      <ul className="md:flex md:items-center gap-6">
        {LINKS
          .filter(({ noAuth, auth }) => (!noAuth || !user) || (auth && user))
          .map(({ to, text }, i) => (
            <li className="my-4 md:my-0" key={`footer-link-${i}`}>
              <NavLink to={to}>{text}</NavLink>
            </li>
          ))
        }
      </ul>

      <div className="w-44 md:w-full">
        © 2023 Growth Clue. All rights reserved.
      </div>

      <CookieBanner />
      <ScrollRestoration />
    </footer>
  )
};
