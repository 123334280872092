import React from 'react';
import { twMerge } from 'tailwind-merge';

const baseClasses = 'inline-block align-middle mr-1 w-5 h-5 z-10 rounded';

export const DecisionStatusIcon = ({ decision, className }) => {
  // Если стоит тоггл Validation is completed и заполнены Resolution date и Resolution description
  // (может не быть текстового описания, но есть загруженные Attachments),
  // то это Validated decision.
  if (decision.validation_completed && decision.resolution_applied && (
    decision.resolution_description || decision.attachments?.length
  )) {
    return (
      <span className={twMerge(
        baseClasses,
        'bg-cyan-400',
        new Date(decision.validation_expires) > new Date() ? 'border-red-500 border-2 border-solid' : '',
        className
      )}></span>
    );
  }
  // Если стоит тоггл Validation is expected и заполнены Resolution date и Resolution description
  // (может не быть текстового описания, но есть загруженные Attachments),
  // то это Expert decision (validation is expected).
  if (decision.validation_expected && decision.resolution_applied && (
    decision.resolution_description || decision.attachments?.length
  )) {
    return (
      <span className={twMerge(
        baseClasses,
        'bg-cyan-200',
        'border-red-500 border-2 border-solid',
        className
      )}></span>
    );
  }
  
  // Если этого нет, но есть дата Resolution и заполнены Resolution date и Resolution description
  // (может не быть текстового описания, но есть загруженные Attachments), то это Expert decision.
  if (decision.resolution_applied && (
    decision.resolution_description || decision.attachments?.length
  )) {
    return (
      <span className={twMerge(
        baseClasses,
        'bg-cyan-200',
        className
      )}></span>
    );
  }

  //Иначе - это Draft.
  return (
    <span
      className={twMerge(
        baseClasses,
        'border-solid border-gray-600 border-2',
        className
      )}
    ></span>
  );
};
