import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '~/components/Loader';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';
import { useLocalStorage } from '~/hooks/useLocalStorage';

export const EmailVerificationPage = () => {
  const navigate = useNavigate();
  const { code } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const apiRequest = useApi();
  const { tutorialProject, setTutorialProject } = useLocalStorage('tutorialProject', null);

  useEffect(() => {
    apiRequest({ url: `/auth/verify-email/${code}` }).then((res) => {
      console.log(res);
      if (res.success) {
        setTutorialProject(true);
        navigate(ROUTES.SETTINGS);
      } else {
        setIsLoading(false);
      }
    });
  }, []);

  return (
    <ModalLikePage>
      <h1 className="w-full text-3xl text-neutral-600 text-left">
        Email verification
      </h1>

      <div className="my-4">
        {isLoading && <Loader />}
      </div>

    </ModalLikePage>
  );
};
