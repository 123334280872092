import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { ProjectActivities } from './components/ProjectActivities';
import { ProjectActivitiesMenu } from './components/ProjectActivitiesMenu';
import { ProjectLayoutPage } from './components/ProjectLayoutPage';
import { ProjectTeamSize } from './components/ProjectTeamSize';
import { useProject } from './hooks/useProject';


export const ProjectActivitiesPage = () => {

  const {
    isLoading: isProjectLoading,
    project,
    setStreams,
    streams,
    streamsMap,
    fullTree,
  } = useProject({
    localStorageKey: 'dashboardStreamIds',
    selectFirstLevel: true,
    withFullTree: true
  });

  const [subPage, setSubPage] = useState('activities');

  // const updateIndicators = (indicators, streams) => {
  //   const { enabledStreams, enabledIndicators } = streams.filter(
  //     (stream) => stream.selected
  //   ).reduce(
  //     (res, stream) => {
  //       res.enabledStreams.add(stream.id);
  //       stream.connected_indicator_ids?.forEach((id) => res.enabledIndicators.add(id));
  //       return res;
  //     },
  //     { enabledStreams: new Set(), enabledIndicators: new Set() }
  //   );

  //   for (const indicator of indicators) {
  //     indicator.disabled = !enabledStreams.has(indicator.project_tree_node_id) && !enabledIndicators.has(indicator.id);
  //   }

  //   setIndicators(indicators.slice());
  // };

  useEffect(() => {
    // if (!id) {
    //   return;
    // }

    // (async () => {
    //   const [
    //     { success, body: project },
    //     { success: successTree, body: { tree } },
    //   ] = await Promise.all([
    //     apiRequest({ url: `/project/${id}` }),
    //     apiRequest({ url: `/project/${id}/tree` }),
    //   ]);

    //   if (!success || !successTree) {
    //     return show('Unknown project', 'danger');
    //   }

    //   setProject(project);

    //   const streamsTree = JSON.parse(JSON.stringify(tree));
    //   streamsTree.children = streamsTree.streams;
    //   const flatStreams = new FlatTree().reduce([streamsTree]);
    //   // Select the first stream w/o children by default.
    //   for (const stream of flatStreams) {
    //     if (!stream.children || stream.children.length === 0) {
    //       stream.selected = true;
    //       break;
    //     }
    //   }
    //   setStreams(flatStreams);

    //   const flatIndicators = new FlatTree().reduce(project.indicators.items || []);

    //   updateIndicators(flatIndicators, flatStreams);

    //   const fullTree = JSON.parse(JSON.stringify(tree));
    //   const flatTree = new FlatTree().reduce([composeFullTree(fullTree)]);
    //   console.log(flatTree)
    //   setTree(flatTree);
    // })();

    // return () => null;
  }, []);

  // if (!project) {
  //   return (
  //     <div className="min-h-screen min-w-screen flex flex-col place-content-between">
  //       <Header />
  //       <div className="h-full text-center my-20"><Loader /></div>
  //       <Footer />
  //     </div>
  //   );
  // }

  return (
    <ProjectLayoutPage
      title={`${project?.name} activities`}
      menu={
        <ProjectActivitiesMenu project={project} streams={streams} setStreams={setStreams} />
      }
      project={project}
      isLoading={isProjectLoading}
    >

      <div className="flex gap-8 mt-10 mb-3">
        <h2 className="flex-1 text-2xl text-purple-600 font-medium text-nowrap">
            Table view
        </h2>
        <div
          className={twMerge(
            'flex-none px-8 text-base font-normal',
            subPage === 'activities' ? 'border-solid border-b-2 border-gray-700' : 'text-gray-400 cursor-pointer',
          )}
          onClick={() => subPage !== 'activities' && setSubPage('activities')} 
        >Items</div>
        <div
          className={twMerge(
            'flex-none px-8 text-base font-normal',
            subPage === 'teamSize' ? 'border-solid border-b-2 border-gray-700' : 'text-gray-400 cursor-pointer',
          )}
          onClick={() => subPage !== 'teamSize' && setSubPage('teamSize')} 
          //  navigate(ROUTES.PROJECT_DASHBOARD.replace(':id', id))}
        >Team size</div>
      </div>

      {subPage === 'activities' && (
        <ProjectActivities project={project} fullTree={fullTree} />
      )}

      {subPage === 'teamSize' && (
        <ProjectTeamSize project={project} streams={streams} streamsMap={streamsMap} />
      )}

    </ProjectLayoutPage>
  );
};
