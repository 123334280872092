import React from 'react';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';
import { useToast } from '~/hooks/useToast';

import { SettingsTabs } from './SettingsTabs';

export const NotificationsPage = () => {
  const apiRequest = useApi();
  const { user, setUser } = useAuth();
  const { show } = useToast();

  const {
    control,
    formState: {isSubmitting, isValid, errors},
    handleSubmit,
  } = useForm({
    defaultValues: {
      schedule_notifications: user.schedule_notifications ?? false,
      news_letter: user.news_letter ?? false,
    }
  });

  const onSubmit = async (data) => {
    const res = await apiRequest({
      url: `/user/change-notifications`,
      method: 'POST',
      data,
    });

    if (res.success) {
      show('Notification settings has been changed successfully.');
      setUser({
        ...user,
        ...data,
      });
    }
  };

  return (
    <ModalLikePage title="Settings">

      <SettingsTabs activeTab="Notifications" />

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Receive schedule notifications"
          name="schedule_notifications"
          type="checkbox"
          control={control}
          errors={errors}
        />
        <p className="-mt-4 text-sm text-gray-500">
          Don’t switch it off if you want to receive alerts on events
          <br />
          from your workspace.
        </p>

        <FormField
          label="Receive news from us"
          name="news_letter"
          type="checkbox"
          control={control}
          errors={errors}
        />

        <SubmitButton
          text="Save settings"
          isActive={isValid}
          isLoading={isSubmitting}
        />

      </Form>

    </ModalLikePage>
  );
}
