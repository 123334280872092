import React from 'react';
import { twMerge } from 'tailwind-merge'

export { Button, SubmitButton } from '../Button';
import { Checkbox } from '../Checkbox';
import { ColorPicker } from '../ColorPicker';
import { DatePicker } from '../DatePicker/DatePicker';
import { Input } from '../Input';
import { Select } from '../Select';
import { TextArea } from '../TextArea';

export const FormField = ({
  type, label, name, placeholder, help, defaultValue, options, className,
  control, register, errors,
  required, minLength, maxLength, pattern, validate,
  ...props
}) => {
  const rules = { required, minLength, maxLength, pattern, validate };

  return (
    <div
      className={twMerge(
        'flex gap-1',
        type === 'checkbox' ? 'flex-row flex-wrap items-center' : 'flex-col items-start',
        className
      )}>

      {type === 'checkbox' && <Checkbox name={name} control={control} rules={rules} />}

      {Boolean(label) && (
        <label
          className={twMerge('text-base', type === 'checkbox' ? 'ml-1' : 'text-neutral-500/80')}
          htmlFor={`${name}__field`}
        >{ label }</label>
      )}

      {type === 'select' && <Select name={name} control={control} options={options} rules={rules} {...props} />}

      {type === 'colorPicker' && <ColorPicker name={name} control={control} rules={rules} {...props} />}

      {type === 'datePicker' && <DatePicker name={name} control={control} rules={rules} {...props} />}

      {type === 'textarea' && (
        <TextArea
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          errors={errors}
          register={register}
          rules={rules}
          {...props}
        />
      )}

      {(!type || type === 'text' || type === 'password' || type === 'email') && (
        <Input
          type={type ?? 'text'}
          name={name}
          defaultValue={defaultValue}
          placeholder={placeholder}
          errors={errors}
          register={register}
          rules={rules}
          {...props}
        />
      )}

      {Boolean(errors?.[name]) && (
        <div className="text-sm text-red-600 max-w-[318px] md:max-w-[473px]" role="alert">
          {errors[name].type === 'required' ? 'This is required':
            errors[name].type === 'maxLength' ? 'Max length exceeded' :
            errors[name].type === 'minLength' ? 'Min length is not reached' :
            (errors[name].message || errors[name].type)
          }
        </div>
      )}

      {Boolean(!errors?.[name] && help) && (
        <div className="text-sm text-neutral-500/80">{help}</div>
      )}
    </div>
  );
};
