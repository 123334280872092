import React, { createContext, useContext, useMemo } from 'react';

import { apiClient } from '~/api';
import { useLocalStorage } from './useLocalStorage';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { user, setUser, removeUser } = useLocalStorage('user', null);

  const login = async (data) => {
    apiClient.accessToken = data.access_token;
    setUser(data);
  };

  const logout = () => {
    setUser(null);
    removeUser();
  };

  const value = useMemo(
    () => {
      if (user && !apiClient.accessToken) {
        apiClient.accessToken = user.access_token;
      } else if (!user && apiClient.accessToken) {
        apiClient.accessToken = null;
      }

      return { login, logout, user, setUser };
    },
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
