import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { HexColorPicker } from 'react-colorful';
import { twMerge } from 'tailwind-merge'

export const ColorPicker = ({ name, control, className, pickerClassName, pickerStyles }) => (
  <Controller
    name={name}
    control={control}
    render={({ field }) => {
      const [isDisplayed, setDisplayed] = useState(false);

      return (
        <div>
          <div
            className={twMerge('w-8 h-8 rounded cursor-pointer', pickerClassName)}
            style={Object.assign({ backgroundColor: field.value }, pickerStyles)}
            onClick={() => setDisplayed(!isDisplayed)}
          />
          {isDisplayed && (
            <div className={twMerge('z-50', pickerClassName)} style={pickerStyles}>
              <HexColorPicker
                {...field}
                onChange={(e) => {field.onChange(e); setDisplayed(false)}}
              />
            </div>
          )}
        </div>
      )
    }}
  />
);
