import React from 'react';

import { Button } from '~/components/Button';
import { NavLink } from '~/components/NavLink';
import { ROUTES } from '~/routes';
import { useLocalStorage } from '~/hooks/useLocalStorage';

export const CookieBanner = () => {
  const { agreeCookiePolicy, setAgreeCookiePolicy } = useLocalStorage('agreeCookiePolicy', false);

  if (agreeCookiePolicy) {
    return null;
  }

  return (
    <div
      className="fixed bottom-6 right-10 w-[560px] px-4 py-3 flex justify-center items-center gap-3 rounded-xl bg-zinc-800"
      style={{ boxShadow: '0px 0px 1px 0px rgba(96, 96, 96, 0.10), 0px 12px 24px -6px rgba(96, 96, 96, 0.15)' }}
    >
      <div className="text-white text-xs leading-5">
        App uses cookies to personalize your experience on our website.
        By continuing to use this site, you agree to our{' '}
        <NavLink
          to={ROUTES.COOKIE}
          className="text-purple-600 active:text-purple-600 visited:text-purple-600"
        >cookie policy</NavLink>.
      </div>

      <Button
        text="Okay"
        className="bg-white w-12 h-7 px-2 py-1 flex gap-2 justify-center items-center self-center border-0 rounded-lg text-zinc-800 text-xs"
        onClick={() => setAgreeCookiePolicy(true)}
      />
    </div>
  );
};
