export const ROUTES = Object.freeze({
  HOME: '/',

  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  SIGNUP_SUCCESS: '/auth/signup/success',
  EMAIL_VERIFICATION: '/auth/verification/:code',
  FORGOT_PASSWORD: '/auth/password/forgot',
  RESET_PASSWORD: '/auth/password/reset',

  PROFILE: '/auth/settings/profile',
  NOTIFICATIONS: '/auth/settings/notifications',
  DELETE_ACCOUNT: '/auth/settings/delete',

  PROJECT_NEW: '/projects/new',
  PROJECT_DASHBOARD: '/projects/:id/dashboard',
  PROJECT_ACTIVITIES: '/projects/:id/activities',
  PROJECT_DELETE: '/projects/:id/delete',
  PROJECT: '/projects/:id',
  PROJECTS: '/projects',

  STREAM_NEW: '/projects/:projectId/streams/new',
  STREAM_DELETE: '/projects/:projectId/streams/:id/delete',
  STREAM: '/projects/:projectId/streams/:id',

  DECISION_NEW: '/projects/:projectId/decisions/new',
  DECISION_DELETE: '/projects/:projectId/decisions/:id/delete',
  DECISION: '/projects/:projectId/decisions/:id',

  TASK_NEW: '/projects/:projectId/tasks/new',
  TASK_DELETE: '/projects/:projectId/tasks/:id/delete',
  TASK: '/projects/:projectId/tasks/:id',

  TEAM_SIZE_RECORD_NEW: '/projects/:projectId/team-size/new',

  ABOUT: '/about',
  PRIVACY: '/privacy',
  COOKIE: '/cookie',
  TERMS: '/terms',
  CONTACTS: '/contacts',
});
