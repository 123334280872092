import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { StackedGroupedBar } from '~/components/Charts/StackedGroupedBar';
import { useApi } from '~/hooks/useApi';
import { monthFormatter } from '~/utils/date';

import * as charts from './components/charts/';
import { ProjectLayoutPage } from './components/ProjectLayoutPage';
import { ProjectDashboardMenu } from './components/ProjectDashboardMenu';
import { useProject } from './hooks/useProject';

const CHART_HEIGHT = 500;
/** @typedef StrStreamId string */
/** @typedef  string 2024,01*/

export const ProjectDashboardPage = () => {
  const {
    isLoading: isProjectLoading,
    project,
    setStreams,
    streams,
    streamsMap
  } = useProject({
    localStorageKey: 'dashboardStreamIds',
    selectFirstLevel: true,
    additionalStreamItems: [
      { id: 'project_average', name: 'Average by the project', },
      { id: 'industry_average', name: 'Average by the industry', },
    ]
  });

  const [aggregationPeriod, setAggregationPeriod] = useState('month');
  // useEffect(() => {
  //   const handleScroll = () => {
  //       const sections = document.querySelectorAll('section[id]');
  //       const scrollPosition = window.pageYOffset;
  
  //       sections.forEach(section => {
  //         const sectionTop = section.offsetTop;
  //         console.log(sectionTop , section.offsetHeight , "" , section , scrollPosition) ;
  //         const sectionBottom = sectionTop + section.offsetHeight;
  
  //         if (scrollPosition >= sectionTop  && scrollPosition < sectionBottom) {
  //           window.history.replaceState(null, null, `#${section.id}`);
  //         }
  //       });
  //     };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <ProjectLayoutPage
      title={`${project?.name} dashboard`}
      menu={
        <ProjectDashboardMenu
          aggregationPeriod={aggregationPeriod}
          setAggregationPeriod={setAggregationPeriod}
          streams={streams}
          setStreams={setStreams}
        />
      }
      project={project}
      isLoading={isProjectLoading}
    >

      <h2 className="mt-10 mb-3 text-2xl text-purple-600 font-medium text-nowrap" id="how-fast">
        How fast are we going?
      </h2>

      <h3 className="text-gray-700 font-medium text-base">
        Quantity of business decisions
      </h3>

      <charts.QuantityDecisionsChart
        aggregationPeriod={aggregationPeriod}
        project={project}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

      <h3 className="mt-6 text-gray-700 font-medium text-base">
        Quantity of business decisions per team member
      </h3>

      <charts.QuantityDecisionsPerMemberChart
        aggregationPeriod={aggregationPeriod}
        project={project}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

      <h3 className="mt-6 text-gray-700 font-medium text-base">
        Distribution of quantities of business decisions per team member
      </h3>

      <charts.DistributionQuantityDecisionsPerMemberChart
        aggregationPeriod={aggregationPeriod}
        project={project}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

      <h3 className="mt-6 text-gray-700 font-medium text-base">
        Quantity of tasks to be done before validation per team member
      </h3>

      <charts.TasksToBeDoneChart
        aggregationPeriod={aggregationPeriod}
        project={project}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

      <h2 className="mt-10 mb-3 text-2xl text-purple-600 font-medium text-nowrap" id="where">
        Where are we going?
      </h2>

      <h2 className="mt-10 mb-3 text-2xl text-purple-600 font-medium text-nowrap" id="how-qualitative">
        How qualitative are our decisions?
      </h2>

      <h3 className="mt-6 text-gray-700 font-medium text-base">
        Share of validated business decisions
      </h3>

      <charts.SharesValidatedDecisionsChart
        project={project}
        aggregationPeriod={aggregationPeriod}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

      <h3 className="mt-6 text-gray-700 font-medium text-base">
        Distribution of shares of validated business decisions
      </h3>

      <charts.DistributionSharesValidatedDecisionsChart
        project={project}
        streams={streams}
        streamsMap={streamsMap}
        height={CHART_HEIGHT}
      />

    </ProjectLayoutPage>
  );
};
