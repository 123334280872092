import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Form, FormField, SubmitButton } from '~/components/Form';
import { cutString } from '~/utils/string';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { FlatTree, Tree } from '~/components/Tree';
import { useApi } from '~/hooks/useApi';
import { useIndicators } from '~/hooks/useIndicators';
import { useToast } from '~/hooks/useToast';

export const StreamPage = () => {
  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      team_size: '',
      color: '#FF0000',
    }
  });
 
  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { show } = useToast();
  const {
    addIndicator,
    getNotReadOnly,
    getSelected,
    setReadOnly,
    setSelected,
    updateIndicators
  } = useIndicators(setError);

  const id = Number(useParams().id);
  const projectId = Number(useParams().projectId);
  const projectUrl = ROUTES.PROJECT_ACTIVITIES.replace(':id', projectId);
  const [project, setProject] = useState();
  const [projectTree, setProjectTree] = useState(() => []);
  const [parent, setParent] = useState();
  const [streamName, indicator] = watch(['name', 'indicator']);
  const [indicators, setIndicators] = useState(() => []);
  let selectedIndicatorsIds = [];

  useEffect(() => {
    if (!projectId) {
      return;
    }

    (async () => {
      const [
        { success, body: project },
        { success: successTree, body: { tree } },
      ] = await Promise.all([
        apiRequest({ url: `/project/${projectId}` }),
        apiRequest({ url: `/project/${projectId}/tree` }),
      ]);

      if (!success || !successTree) {
        return show('Unknown project', 'danger');
      }

      setProject(project);
      setReadOnly(project.indicators.items, id);
      setIndicators(project.indicators.items);
      tree.children = tree.streams;
      tree.selected = true;
      setProjectTree([tree]);
      const flatTree = new FlatTree().reduce([tree]);

      if (id) {
        const { success, body } = await apiRequest({ url: `/stream/${id}` });
        if (!success) {
          return show('Unknown stream', 'danger');
        }

        // setParent(flatTree.find(node => node.id ===));

        setValue('name', body.name);
        setValue('color', body.color)
        setValue('description', body.description ?? '');
        setValue('team_size', body.team_size ?? '');
        selectedIndicatorsIds = body.connected_indicator_ids ?? [];
        if (selectedIndicatorsIds.length) {
          setSelected(project.indicators.items, selectedIndicatorsIds);
          setIndicators(project.indicators.items);
        }
        trigger();
      } else {
        setParent(project);
      }
    })();

    return () => null;
  }, [projectId]);

  const onSubmit = async ({ name, color, description, team_size }, e) => {
    const selectedIndicators = getSelected(indicators);
    if (!selectedIndicators.length && !getNotReadOnly(indicators).length) {
      setError('indicators', {
        message: 'At least one indicator is required',
        type: 'manual',
      });
      return;
    }

    const data = { stream_id: id, project_id: projectId, name, color, description, team_size };
    if (parent?.id && parent?.color) {
      data.parent_id = parent.id;
    }
    const { success, body } = await apiRequest({
      url: '/stream/',
      method: id ? 'PUT' : 'POST',
      data,
    });

    if (!success) {
      return;
    }

    await updateIndicators(indicators, projectId, id || body.id);

    const newSelectedIndicatorsIds = selectedIndicators.reduce((acc, ind) => {
      acc.add(ind.id);
      return acc;
    }, new Set(selectedIndicatorsIds));

    if (
      newSelectedIndicatorsIds.size !== selectedIndicatorsIds.length ||
      selectedIndicatorsIds.some((id) => !newSelectedIndicatorsIds.has(id))
    ) {
      body.stream_id = id || body.id;
      body.connected_indicator_ids = Array.from(newSelectedIndicatorsIds);
      await apiRequest({
        url: '/stream/',
        method: 'PUT',
        data: body,
      });
    }

    navigate(projectUrl);
  };

  return (
    <ModalLikePage
      title={id ? `Edit stream`: 'New stream'}
      isInitLoading={(id && !streamName) || (!id && !indicators.length)}
    >

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Stream name"
          name="name"
          placeholder="New stream"
          register={register}
          errors={errors}
          required
        />

        <FormField
          name="color"
          type="colorPicker"
          control={control}
          errors={errors}
          required
          setValue={setValue}
          className="relative flex-none -mb-8"
          pickerClassName={`absolute right-4`}
          pickerStyles={{ top: `${-75 - (errors.name?.type ? 24 : 0)}px`}}
        />

        <Tree
          label="Parent"
          controls={false}
          data={projectTree}
          click={(parent) => setParent(parent)}
        />

        <FormField
          label="Stream description"
          name="description"
          placeholder="Stream description"
          type="textarea"
          rows={3}
          register={register}
          errors={errors}
        />

        <Tree
          label="Key indicators"
          data={indicators}
          setIndicators={setIndicators}
          clearErrors={() => {
            clearErrors('indicators');
            trigger();
          }}
        />
        {Boolean(errors?.indicators) && (
          <div className="text-sm text-red-600 -mt-6" role="alert">
            {errors.indicators.message || errors.indicators.type}
          </div>
        )}

        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <FormField
              name="indicator"
              placeholder="New indicator"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="">
            <Button
              text="Add indicator"
              className="h-8 text-xs mt-4"
              onClick={(e) => addIndicator(indicator, setIndicators, setValue, clearErrors)}
            />
          </div>
        </div>

        <FormField
          label="Initial team size (changers)"
          name="team_size"
          placeholder="5"
          register={register}
          errors={errors}
          required
        />

        <div className="flex gap-6">
          <div className="flex-1">
            <SubmitButton text={id ? 'Save' : 'Create'} isActive={isValid} isLoading={isSubmitting} />
          </div>
          <div className="flex-1">
            <Button text="Back to project" onClick={() => navigate(projectUrl)} />
          </div>
        </div>

      </Form>

    </ModalLikePage>
  );
};
