import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import Calendar from 'react-calendar';

import 'react-calendar/dist/Calendar.css';
import './DatePicker.css'

import { dateFormatter } from '~/utils/date';

const DateField = ({ name, errors, ...props }) => {
  const [isDisplayed, setDisplayed] = useState(false);

  return (
    <>
      <input
        id={`${name}__field`}
        type="text"
        placeholder="DD.MM.YYYY"
        className="w-full h-14 rounded-xl border border-solid px-6 py-4 border-neutral-500/25 text-neutral-900"
        aria-invalid={errors?.[name] ? 'true' : 'false'}
        onClick={() => setDisplayed(true)}
        {...props}
        onBlur={(e) => {
          props.onBlur?.(e);
          // console.log('RARARARA', e.target)
          // setDisplayed(false);
        }}
      />

      {isDisplayed && (
        <div style={{
          boxShadow: '0 1px 4px rgba(0, 0, 0, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
        }}>
          <Calendar
            onChange={(value) => {
              props.onChange?.(dateFormatter.format(value));
              setDisplayed(false);
            }}
          />
        </div>
      )}

    </>
  );
};

export const DatePicker = ({ name, control, defaultValue, errors, rules, ...props }) => {
  if (!control) {
    return <DateField name={name} errors={errors} {...props} />;
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => <DateField name={name} errors={errors} {...field} />}
    />
  );
};
