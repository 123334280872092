import { useState } from 'react';

export const useLocalStorage = (keyName, defaultValue, flat=false) => {
  if (!keyName) {
    throw new Error('keyName is required');
  }

  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);
      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  const removeValue = () => {
    try {
      window.localStorage.removeItem(keyName);
    } catch (err) {}
  }

  const upperKey = keyName[0].toUpperCase() + keyName.substring(1);

  return flat ? [storedValue, setValue, removeValue] : {
    [keyName]: storedValue,
    ['set' + upperKey]: setValue,
    ['remove' + upperKey]: removeValue,
  };
};
