import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '~/hooks/useAuth';
import { ROUTES } from '~/routes';

export const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate(ROUTES.LOGIN);
    }
  }, [user]);

  return children;
};
