import React from 'react';
import { useNavigate } from 'react-router-dom';

import { BaseTree } from '~/components/Tree';
import { Button } from '~/components/Form';
import { dateFormatter } from '~/utils/date';
import { ROUTES } from '~/routes';

const getTypeRoute = (node, operation, projectId) => {
  const routeName = (
    node.type +
    (operation === 'edit' ? '' : '_' + operation)
  ).toUpperCase();

  return ROUTES[routeName]
    .replace(':projectId', projectId)
    .replace(':id', node.id)
}

const dateFormat = (value) => dateFormatter.format(new Date(value));

export const ProjectActivities = ({ fullTree, project }) => {
  const navigate = useNavigate();

  return (
    <>
      <BaseTree
        nodes={fullTree}
        gridHeaders={['Activities', 'Updated', 'Started', 'Done', 'Expires',]}
        gridColumns={[
          { field: 'updated_at', className: 'w-24', formatter: dateFormat },
          { field: 'created_at', className: 'w-24', formatter: dateFormat },
          { field: 'resolution_applied', className: 'w-24', formatter: dateFormat },
          { field: 'validation_expires', className: 'w-24', formatter: dateFormat },
        ]}
        treeClassName="h-full overflow-y-auto"
        style={{ height: '66vh' }}
        handleActions={(action, node, data) => {
          console.log(action, node, data);
          if (action === 'click') {
            return;
          }
          const route = getTypeRoute(node, action, project.id);
          if (route) {
            navigate(route);
          }
        }}
        // click={(parent) => setParent(parent)}
      />

      <div className="flex gap-4 mt-12 justify-end">
        <Button
          text="New stream"
          className="flex-none w-[252px] bg-white text-neutral-900 border-solid border-2"
          onClick={() => navigate(ROUTES.STREAM_NEW.replace(':projectId', project.id))}
        />
        <Button
          text="New decision"
          className="flex-none w-[252px] bg-cyan-500"
          onClick={() => navigate(ROUTES.DECISION_NEW.replace(':projectId', project.id))}
        />
        <Button
          text="New task"
          className="flex-none w-[252px] bg-purple-600"
          onClick={() => navigate(ROUTES.TASK_NEW.replace(':projectId', project.id))}
        />
      </div>
    </>
  );
}
