import React, { useEffect, useState } from 'react';

import { BaseTree, composeFullTree, FlatTree } from '~/components/Tree';
import { FormField } from '~/components/Form/FormField';

export const ProjectActivitiesMenu = ({ project, streams, setStreams }) => {
  const [indicators, setIndicators] = useState(() => []);

  const updateIndicators = (indicators, streams) => {
    const { enabledStreams, enabledIndicators } = streams.filter(
      (stream) => stream.selected
    ).reduce(
      (res, stream) => {
        res.enabledStreams.add(stream.id);
        stream.connected_indicator_ids?.forEach((id) => res.enabledIndicators.add(id));
        return res;
      },
      { enabledStreams: new Set(), enabledIndicators: new Set() }
    );

    for (const indicator of indicators) {
      indicator.disabled = !enabledStreams.has(indicator.project_tree_node_id) && !enabledIndicators.has(indicator.id);
    }

    setIndicators(indicators.slice());
  };

  useEffect(() => {
    const flatIndicators = new FlatTree().reduce(project.indicators.items || []);
    updateIndicators(flatIndicators, streams);
  }, [project.indicators.items, streams]);

  return (
    <div className="sticky top-0">
      <h2 className="text-3xl font-medium mb-7">Filters</h2>

      <BaseTree
        label="Streams"
        nodes={streams}
        onSelect={(node, value) => setStreams((prev) => {
          prev[node.idx].selected = value;

          // Toggle all child nodes:
          for (let i = node.idx + 1, depth = node.depth; i < prev.length && prev[i].depth > depth; i++) {
            prev[i].selected = value;
          }

          updateIndicators(indicators, prev);

          return prev.slice();
        })}
      />

      <BaseTree
        label="Key indicators"
        nodes={indicators}
        onSelect={(node, value) => setIndicators((prev) => {
          prev[node.idx].selected = value;
          return prev.slice();
        })}
        className="mt-5"
      />

      {/* <Calendar onChange={(e) => console.log(e)} value={new Date()} /> */}

      <FormField
        label="Show validated decisions"
        name="validated-decisions"
        type="checkbox"
        className="mt-5"
      />

      <FormField
        label="Show expert decisions"
        name="expert-decisions"
        type="checkbox"
        className="mt-5"
      />

      <FormField
        label={<div>Show decisions with expected or<br/> outdated validations</div>}
        name="outdated-decisions"
        type="checkbox"
        className="mt-5"
      />

      <FormField
        label="Show scheduled and drafts"
        name="drafts-decisions"
        type="checkbox"
        className="mt-5"
      />

      <FormField
        label="Show tasks"
        name="tasks"
        type="checkbox"
        className="mt-5"
      />

      <FormField
        label="Switch to archived items"
        name="archived"
        type="checkbox"
        className="mt-5"
      />

      {/* <div className="mt-5">
        <div className="flex gap-1 items-start flex-row flex-wrap">
          <Switch name="validated-decisions" />
          <label className="text-base ml-1" htmlFor="validated-decisions">Show validated decisions</label>
        </div>
      </div> */}

    </div>
  );
};
