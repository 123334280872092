import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '../routes';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';

export const SignupPage = () => {
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit, 
    register,
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });

  const apiRequest = useApi(setError);
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate(ROUTES.SETTINGS);
    } else {
      setFocus('email');
    }

  }, []);

  const onSubmit = async ({ email, password }) => {
    const res = await apiRequest({
      url: '/auth/sign-up',
      method: 'POST',
      data: { email, password },
    });

    if (res.success) {
      navigate(ROUTES.SIGNUP_SUCCESS);
    }
  };

  return (
    <ModalLikePage title="Create an account" subheader={
      <p className="w-full text-left mt-2 text-neutral-600">
        Already have an account?{' '}
        <Link to={ROUTES.LOGIN} className="underline text-neutral-600 visited:text-neutral-600">
          Log in
        </Link>
      </p>
    }>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Email address"
          name="email"
          type="email"
          autoComplete="email"
          register={register}
          errors={errors}
          required="Field is required"
        />

        <FormField
          label="Password"
          name="password"
          type="password"
          autoComplete="new-password"
          register={register}
          errors={errors}
          help="Use 8 or more characters with a mix of letters & numbers."
          required="Field is required"
          minLength={8}
        />

        <p className="w-full text-left mt-2 text-neutral-600">
          By creating an account, you agree to our<br />
          <Link to={ROUTES.TERMS} className="underline text-neutral-600 visited:text-neutral-600">
            Terms of Service
          </Link>
          {' '}and{' '}
          <Link to={ROUTES.PRIVACY} className="underline text-neutral-600 visited:text-neutral-600">
            Privacy Policy
          </Link>.
        </p>

        <SubmitButton text="Create an account" isActive={isValid} isLoading={isSubmitting} />

      </Form>

      <p className="w-full text-left mt-6 text-neutral-600">
        Already have an account?{' '}
        <Link to={ROUTES.LOGIN} className="underline text-neutral-600 visited:text-neutral-600">
          Log in
        </Link>
      </p>

    </ModalLikePage>
  );
};

export const SignupSuccessPage = () => {
  return (
    <ModalLikePage>
      <h1 className="w-full text-3xl text-neutral-600 text-left">
        Check your email
      </h1>

      <p className="w-full text-left mt-8 text-neutral-600">
        We sent you instructions on how to verify your account.
      </p>

    </ModalLikePage>
  );
};
