import React, { useEffect, useRef } from 'react';
import { Link, useNavigate} from 'react-router-dom';

import Logo from './logo.svg?react';
import { NavLink } from '~/components/NavLink';
import { ROUTES } from '~/routes';
import { useAuth } from '~/hooks/useAuth';

const LINKS = [
  { to: ROUTES.LOGIN, text: 'Log In' },
  { to: ROUTES.SIGNUP, text: 'Sign Up' },
];

const USER_LINKS = [
  { to: ROUTES.PROJECTS, text: 'My projects' },
  { to: ROUTES.PROFILE, text: 'Settings' },
  { isLogout: true, text: 'Logout' },
];

export const Header = ({ title }) => {
  const menu = useRef();

  const navigate = useNavigate();
  const { logout, user } = useAuth();

  const toggleMenu = () => {
    menu.current.classList.toggle('hidden');
    menu.current.classList.toggle('w-full');
    menu.current.classList.toggle('h-screen');
  };

  useEffect(() => {
    document.title = title || 'GrowthClue';
    return;
  }, [title]);

  return (
    <header className="w-full h-40 bg-neutral-900 drop-shadow-lg flex place-content-between items-stretch gap-12 md:items-center md:gap-32 px-12 md:px-80 pt-4 md:pt-8 pb-4 md:pb-8">
      <Link to={ROUTES.HOME} className="text-primary-600 flex items-center gap-4 text-3xl font-bold">
        <Logo />
        GrowthClue
      </Link>

      <ul
        ref={menu}
        className="hidden fixed top-0 right-0 px-10 py-16 bg-neutral-900 z-50 md:relative md:flex md:p-0 md:bg-transparent md:flex-row md:space-x-6 text-4xl md:text-2xl text-white"
      >
        <li key={0} className="absolute md:hidden z-90 top-16 right-6">
            <a
              href="#"
              className="text-white text-right text-5xl"
              onClick={toggleMenu}
            >
              &times;
            </a>
        </li>

        {(user ? USER_LINKS : LINKS).map(({ to, text, isLogout }, i) => (
          <li key={i + 1} className="pb-4 md:py-0">
            <NavLink
              className="text-white text-2xl"
              to={to}
              onClick={
                isLogout && (() => {logout(); navigate(ROUTES.HOME);})
              }
            >
              {text}
            </NavLink>
          </li>
        ))}
      </ul>

      <div className="flex items-center md:hidden">
        <button className="text-white text-5xl font-bold opacity-70 hover:opacity-100 duration-300" onClick={toggleMenu}>
          &#9776;
        </button>
      </div>
    </header>
  )
};
