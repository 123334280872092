import { useApi } from './useApi';

export const useIndicators = (setError) => {
  const apiRequest = useApi(setError);

  const setReadOnly = (tree, id) => {
    for (const node of tree) {
      if (node.project_tree_node_id !== id) {
        node.readOnly = true;
      }
      if (node.children?.length) {
        setReadOnly(node.children, id);
      }
    }
  }

  const getNotReadOnly = (tree, result = null) => {
    if (!result) {
      result = [];
    }

    for (const node of tree) {
      if (!node.readOnly) {
        result.push(node);
      }
      if (node.children?.length) {
        getNotReadOnly(node.children, result);
      }
    }

    return result;
  }

  const setSelected = (tree, ids) => {
    for (const node of tree) {
      if (ids.includes(node.id)) {
        node.selected = true;
      }
      if (node.children?.length) {
        setSelected(node.children, ids);
      }
    }
  }

  const getSelected = (tree, result = null) => {
    if (!result) {
      result = [];
    }

    for (const node of tree) {
      if (node.selected) {
        result.push(node);
      }
      if (node.children?.length) {
        getSelected(node.children, result);
      }
    }

    return result;
  }

  const addIndicator = (indicator, setIndicators, setValue, clearErrors) => {
    let i = 0;
    // e.preventDefault();
    clearErrors('indicators');
    setValue('indicator', '');

    setIndicators((prev) => {
      if (i) {
        return prev;
      }
      i++;

      if (!prev.length) {
        return [{ name: indicator, order_weight: 10 }];
      }

      const newIndicators = [...prev];
      const parent = newIndicators[newIndicators.length - 1]
      parent.children.push({
        name: indicator,
        order_weight: parent.children[parent.children.length - 1]?.order_weight ?? 10,
      });

      return newIndicators;
    });
  }

  const updateIndicators = async (indicators, projectId = null, streamId = null, parentId = null) => {
    for (const indicator of indicators) {
      if (!indicator.readOnly) {
        if (indicator.deleted && !indicator.id?.startsWith?.('new_')) {
          const { success, body } = await apiRequest({
            url: `/indicator/${indicator.id}`,
            method: 'DELETE',
          });

        } else if (indicator.id?.startsWith?.('new_')) {
          const { success, body } = await apiRequest({
            url: '/indicator/',
            method: 'POST',
            data: {
              name: indicator.name,
              project_id: projectId || undefined,
              stream_id: streamId || undefined,
              parent_id: parentId || undefined
            },
          });
          if (success) {
            indicator.id = body.id;
          }

        } else if (indicator.changed) {
          const { success, body } = await apiRequest({
            url: '/indicator/move',
            method: 'POST',
            data: { indicator_id: indicator.id, parent_id: parentId },
          });
        }
        if (indicator.weightChanged) {
          const { success, body } = await apiRequest({
            url: '/indicator/',
            method: 'PUT',
            data: { indicator_id: indicator.id, name: indicator.name, order_weight: indicator.order_weight },
          });
        }
      }

      if (indicator.children?.length) {
        await updateIndicators(indicator.children, projectId, streamId, indicator.id);
      }
    }
  }

  return { addIndicator, getNotReadOnly, getSelected, setReadOnly, setSelected, updateIndicators };
};
