import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { composeFullTree, FlatTree } from '~/components/Tree';
import { useApi } from '~/hooks/useApi';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { useToast } from '~/hooks/useToast';

export const useProject = ({
  additionalStreamItems = null,
  withFullTree = false,
  localStorageKey = null,
  selectFirstLevel = false,
} = {}) => {
  const apiRequest = useApi();
  const { id, projectId } = useParams();
  const { show } = useToast();
  const [
    storedStreamIds,
    setStoredStreamIds
  ] = localStorageKey ? useLocalStorage(localStorageKey, [], true) : [];

  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState(() => {});
  const [streams, setStreams] = useState(() => []);
  const [streamsMap, setStreamsMap] = useState(() => []);
  const [fullTree, setFullTree] = useState(() => []);

  useEffect(() => {
    if (!id && !projectId) {
      return;
    }

    (async () => {
      const [
        { success, body: project },
        { success: successTree, body: { tree } },
      ] = await Promise.all([
        apiRequest({ url: `/project/${id || projectId}` }),
        apiRequest({ url: `/project/${id || projectId}/tree` }),
      ]);

      if (!success || !successTree) {
        setIsLoading(false);
        return show('Unknown project', 'danger');
      }

      setProject(project);

      const streamsTree = JSON.parse(JSON.stringify(tree));
      streamsTree.children = streamsTree.streams;
      const flatStreams = new FlatTree();
      flatStreams.reduce([streamsTree]);
      if (additionalStreamItems) {
        for (const item of additionalStreamItems) {
          flatStreams.push(item);
        }
      }

      const hasStreamIds = Boolean(storedStreamIds?.length);
      let streamIds = hasStreamIds ? new Set(storedStreamIds) : new Set();
      flatStreams.nodes.forEach((stream) => {
        if (hasStreamIds && streamIds.has(stream.id)) {
          stream.selected = true
        } else if (selectFirstLevel && !hasStreamIds && stream.color && stream.depth === 1) {
          stream.selected = true;
          streamIds.add(stream.id);
        }
      });
      if (!storedStreamIds?.length && setStoredStreamIds) {
        setStoredStreamIds(Array.from(streamIds));
      }

      setStreams(flatStreams.nodes);

      setStreamsMap(
        flatStreams.nodes.reduce((map, stream) => {
          if (stream.color) {
            map[stream.id] = { name: stream.name, color: stream.color };
          }
          return map;
        }, {})
      );

      if (withFullTree) {
        const fullTree = JSON.parse(JSON.stringify(tree));
        const flatTree = new FlatTree().reduce([composeFullTree(fullTree)]);
        setFullTree(flatTree);
      }

      setIsLoading(false);
    })();

    return;
  }, [id]);

  return {
    isLoading,
    project,
    setStreams: setStoredStreamIds ? (value) => {
      setStreams(value);
      setStoredStreamIds(streams.filter((stream) => stream.selected).map((stream) => stream.id));
    } : setStreams,
    streams,
    streamsMap,
    fullTree,
    setFullTree,
  };
}
