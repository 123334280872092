import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { Button, Form, FormField, SubmitButton } from '~/components/Form';
import { cutString } from '~/utils/string';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { Tree } from '~/components/Tree';
import { useApi } from '~/hooks/useApi';
import { useIndicators } from '~/hooks/useIndicators';

export const ProjectPage = () => {
  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      industry: '',
      indicator: '',
    }
  });
  const [projectName, indicator] = watch(['name', 'indicator']);

  const { id } = useParams();
  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { addIndicator, updateIndicators } = useIndicators();

  const [industries, setIndustries] = useState(() => []);
  const [indicators, setIndicators] = useState(() => []);

  useEffect(() => {
    apiRequest({ url: '/project/industries', cache: 'force-cache' })
      .then(({success, body: industries}) => {
        success && setIndustries(industries);

        if (id) {
          apiRequest({ url: `/project/${id}` }).then(({success, body}) => {
            if (success) {
              setValue('name', body.name);
              setValue('description', body.description ?? '');
              setValue('industry', {
                value: body.industry,
                label: industries.find(i => i.id === body.industry)?.name,
              });
              setIndicators(body.indicators.items);
              trigger();
            }
          })
        }
      });

    return () => null;
  }, []);

  const onSubmit = async ({ name, description, industry }, e) => {
    if (!indicators?.length) {
      setError('indicators', {
        message: 'At least one indicator is required',
        type: 'manual',
      });
      return;
    }

    const { success, body } = await apiRequest({
      url: '/project/',
      method: id ? 'PUT' : 'POST',
      data: { project_id: id ?? null, name, description, industry: industry.value },
    });
    if (!success) {
      return;
    }

    await updateIndicators(indicators, id || body.id);

    navigate(ROUTES.PROJECTS);
  };

  return (
    <ModalLikePage
      title={id ? `Edit project ${cutString(projectName)}`: 'New project'}
      isInitLoading={(id && !projectName) || (!id && !industries.length)}
    >

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Project name"
          name="name"
          placeholder="New project"
          register={register}
          errors={errors}
          required
        />

        <FormField
          label="Project description"
          name="description"
          placeholder="Some description"
          type="textarea"
          rows={3}
          register={register}
          errors={errors}
        />

        <FormField
          label="Industry"
          name="industry"
          type="select"
          control={control}
          errors={errors}
          options={industries.map(({ id, name }) => ({ value: id, label: name }))}
          required
        />

        <Tree label="Key indicators" data={indicators} setIndicators={setIndicators} />
        {Boolean(errors?.indicators) && (
          <div className="text-sm text-red-600 -mt-6" role="alert">
            {errors.indicators.message || errors.indicators.type}
          </div>
        )}

        <div className="grid grid-cols-4 gap-4">
          <div className="col-span-3">
            <FormField
              name="indicator"
              placeholder="New indicator"
              type="text"
              register={register}
              errors={errors}
            />
          </div>
          <div className="">
            <Button
              text="Add indicator"
              className="h-8 text-xs mt-4"
              onClick={(e) => addIndicator(indicator, setIndicators, setValue, clearErrors)}
            />
          </div>
        </div>


        <div className="flex gap-6">
          <div className="flex-1">
            <SubmitButton
              text={id ? 'Save' : 'Create'}
              isActive={isValid && projectName}
              isLoading={isSubmitting}
            />
          </div>
          <div className="flex-1">
            <Button text="Back to projects" onClick={() => navigate(ROUTES.PROJECTS)} />
          </div>
        </div>

      </Form>

    </ModalLikePage>
  );
};
