import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button, Form, FormField, SubmitButton } from '~/components/Form';
import { cutString } from '~/utils/string';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { Switch } from '~/components/Switch';
import { useApi } from '~/hooks/useApi';
import { useAuth }  from '~/hooks/useAuth';

export const StreamDeletePage = () => {
  const {
    control,
    formState: {isSubmitting, isValid, errors},
    handleSubmit,
    setError,
  } = useForm({ defaultValues: { agree: false } });

  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { id, projectId } = useParams();
  const projectUrl = ROUTES.PROJECT_ACTIVITIES.replace(':id', projectId ?? '');
  const [streamName, setStreamName] = useState('');

  useEffect(() => {
    apiRequest({ url: `/stream/${id}` })
      .then(({ success, body }) => success && setStreamName(cutString(body?.name)));

    return () => null;
  }, [id]);

  const onSubmit = async (data) => {
    const res = await apiRequest({
      url: `/stream/${id}`,
      method: 'DELETE',
    });

    if (res.success) {
      navigate(projectUrl);
    }
  };

  return (
    <ModalLikePage title={`Delete stream ${streamName}`}>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="I understand that I will lose all attached streams, and all linked items with the only connection to the stream will be deleted."
          name="agree"
          type="checkbox"
          control={control}
          errors={errors}
          required
        />

        <div className="flex gap-6">
          <div className="flex-1">
            <SubmitButton
              text="Delete this stream"
              className="h-32 md:h-16"
              isActive={isValid && streamName}
              isLoading={isSubmitting}
              isDanger
            />
          </div>
          <div className="flex-1">
            <Button text="Back to the project" onClick={() => navigate(projectUrl)} />
          </div>
        </div>

      </Form>

    </ModalLikePage>
  );
}
