import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Loader } from '~/components/Loader';

import { Button } from '~/components/Form';
import { dateFormatter } from '~/utils/date';
import { Grid } from '~/components/Grid';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';

const dateFormat = (value) => dateFormatter.format(new Date(value));

export const ProjectTeamSize = ({ project, streams, streamsMap }) => {
  const [isLoading, setIsLoading] = useState();
  const [teamRecords, setTeamRecords] = useState(() => []);
  const apiRequest = useApi();

  useEffect(() => {
    const selectedStreams = streams.filter(stream => stream.color && stream.selected);
    if (selectedStreams.length) {
      setIsLoading(true);
      // TODO: remove slice
      /*Promise.all(selectedStreams.slice(0, 1).map(
        ({ id }) => apiRequest({ url: `/team/stream/${id}` })
      )).then((items) => {
        items.reduce((records, { success, body }) => {
          console.log(body);
        }, []);
        setIsLoading(false);
      });*/
      const data = [
        {
          change_date: '2023-12-26',
          created_at: '2024-01-02T21:07:59.896725Z',
          id: 2,
          reason: 'Brandon K. has left the team',
          size: 8,
          stream_id: 2,
        },
        {
          change_date: '2023-12-21',
          created_at: '2023-12-031T21:07:59.896725Z',
          id: 3,
          reason: 'Initial team size',
          size: 9,
          stream_id: 2,
        },
      ];
      data.forEach((item) => item.stream = streamsMap[item.stream_id] ?? '');
      setTeamRecords(data);
      setIsLoading(false);
    }
  }, [streams]);

  if (isLoading) {
    return (
      <div className="w-full flex items-center justify-center"><Loader /></div>
    );
  }

  console.log(streams);
  return (
    <>
      <Grid
        items={teamRecords}
        columns={[
          { field: 'reason', header: 'Reason', className: 'flex-auto text-left' },
          { field: 'stream', header: 'Stream', className: 'w-64', formatter: (stream) => <span style={{color: stream.color}}>{stream.name}</span> },
          { field: 'size', header: 'Actual size', className: 'w-36' },
          { field: 'change_date', header: 'Date', className: 'w-36', formatter: dateFormat },
        ]}
      />

      <div className="flex gap-4 mt-12 justify-end">
        <Link to={ROUTES.TEAM_SIZE_RECORD_NEW.replace(':projectId', project.id)}>
          <Button
            text="Add record"
            className="flex-none w-[252px] bg-white text-neutral-900 border-solid border-2"
          />
        </Link>
      </div>

    </>
  );
};
