import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseTree, composeFullTree, FlatTree } from '~/components/Tree';
import { Button, Form, FormField, SubmitButton } from '~/components/Form';
import { dateFormatter } from '~/utils/date';
import { DecisionStatusIcon } from '~/components/DecisionStatusIcon';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';
import { useIndicators } from '~/hooks/useIndicators';
import { useProject } from './projects/hooks/useProject';
import { useToast } from '~/hooks/useToast';

export const TeamSizePage = () => {
  const {
    isLoading,
    project,
    setStreams,
    streams,
    streamsMap,
    fullTree,
  } = useProject();

  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      importance: 5,
      validation_description: '',
      validation_completed: false,
      validation_expires: '',
      resolution_description: '',
      resolution_applied: '',
    }
  });
 
  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { show } = useToast();

  const projectUrl = ROUTES.PROJECT_ACTIVITIES.replace(':id', project?.id);
  // const [tree, setTree] = useState(() => []);
  // const [parent, setParent] = useState();
  const [
    decisionName,
    validationCompleted,
    validationDescription,
    resolutionDescription,
    resolutionApplied,
  ] = watch([
    'name',
    'validation_completed',
    'validation_description',
    'resolution_description',
    'resolution_applied',
  ]);

  useEffect(() => {
    // if (!project?.id) {
    //   return;
    // }

    // (async () => {
    //   const [
    //     { success, body: project },
    //     { success: successTree, body: { tree } },
    //   ] = await Promise.all([
    //     apiRequest({ url: `/project/${projectId}` }),
    //     apiRequest({ url: `/project/${projectId}/tree` }),
    //   ]);

    //   if (!success || !successTree) {
    //     return show('Unknown project', 'danger');
    //   }

    //   setProject(project);
    //   // setReadOnly(project.indicators.items, id);
    //   const flatIndicators = new FlatTree().reduce(project.indicators.items);
    //   setIndicators(flatIndicators);
    //   // tree.children = tree.streams;
    //   // tree.selected = true;
    //   // setProjectTree([tree]);
    //   // const flatTree = new FlatTree().reduce([tree]);

    //   if (id) {
    //     const { success, body } = await apiRequest({ url: `/decision/${id}` });
    //     if (!success) {
    //       return show('Unknown decision', 'danger');
    //     }

    //     // setParent(flatTree.find(node => node.id ===));

    //     setValue('name', body.name);
    //     setValue('importance', body.importance)
    //     setValue('validation_description', body.validation_description ?? '');
    //     setValue('validation_completed', body.validation_completed);
    //     if (body.validation_expires) {
    //       setValue('validation_expires', dateFormatter.format(new Date(body.validation_expires)));
    //     }
    //     setValue('resolution_description', body.resolution_description ?? '');
    //     if (body.resolution_applied) {
    //       setValue('resolution_applied', dateFormatter.format(new Date(body.resolution_applied)));
    //     }
    //     selectedIndicatorsIds = body.connected_indicator_ids ?? [];
    //     // if (selectedIndicatorsIds.length) {
    //     //   setSelected(project.indicators.items, selectedIndicatorsIds);
    //     //   setIndicators(project.indicators.items);
    //     // }
    //     // trigger();
    //   } else {
    //     // setParent(project);
    //   }

    //   const fullTree = JSON.parse(JSON.stringify(tree));
    //   const flatTree = new FlatTree().reduce([composeFullTree(fullTree)]);
    //   setTree(flatTree);

    // })();

    // return () => null;
  }, [project?.id]);

  const onSubmit = async ({ name, color, description }, e) => {
    // const selectedIndicators = getSelected(indicators);
    // if (!selectedIndicators.length && !getNotReadOnly(indicators).length) {
    //   setError('indicators', {
    //     message: 'At least one indicator is required',
    //     type: 'manual',
    //   });
    //   return;
    // }

    // const data = { stream_id: id, project_id: projectId, name, color, description, team_size };
    // if (parent?.id && parent?.color) {
    //   data.parent_id = parent.id;
    // }
    // const { success, body } = await apiRequest({
    //   url: '/stream/',
    //   method: id ? 'PUT' : 'POST',
    //   data,
    // });

    // if (!success) {
    //   return;
    // }

    // await updateIndicators(indicators, projectId, id || body.id);

    // const newSelectedIndicatorsIds = selectedIndicators.reduce((acc, ind) => {
    //   acc.add(ind.id);
    //   return acc;
    // }, new Set(selectedIndicatorsIds));

    // if (
    //   newSelectedIndicatorsIds.size !== selectedIndicatorsIds.length ||
    //   selectedIndicatorsIds.some((id) => !newSelectedIndicatorsIds.has(id))
    // ) {
    //   body.stream_id = id || body.id;
    //   body.connected_indicator_ids = Array.from(newSelectedIndicatorsIds);
    //   await apiRequest({
    //     url: '/stream/',
    //     method: 'PUT',
    //     data: body,
    //   });
    // }

    navigate(projectUrl);
  };

  return (
    <ModalLikePage
      title="Add team size record"
      isInitLoading={isLoading}
    >

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Change reason"
          name="reason"
          placeholder="Initial size"
          register={register}
          errors={errors}
          minLength={2}
          maxLength={255}
          required
        />

        <BaseTree
          label="Team"
          nodes={streams}
          onSelect={(node, value) => setStreams((prev) => {
            prev[node.idx].selected = value;
            return prev.slice();
          })}
        />

        <FormField
          label="New team size (changers)"
          name="team_size"
          placeholder="5"
          register={register}
          errors={errors}
          required
        />

        <FormField
          label="Change date"
          name="change_date"
          type="datePicker"
          control={control}
          errors={errors}
          required
        />

        <div className="flex gap-6">
          <div className="flex-1">
            <SubmitButton text="Add" isActive={isValid} isLoading={isSubmitting} />
          </div>
          <div className="flex-1">
            <Button text="Back to project" onClick={() => navigate(projectUrl)} />
          </div>
        </div>

      </Form>

    </ModalLikePage>
  );
};
