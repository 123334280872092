import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '../routes';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';
import { useToast } from '~/hooks/useToast';

export const ResetPasswordPage = () => {
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    setError,
    setFocus,
  } = useForm({ defaultValues: { email: '' } });

  const location = useLocation();
  const accessToken = new URLSearchParams(location.search.substring(1)).get('access_token');

  const navigate = useNavigate();
  const { user } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isSent, setIsSent] = useState(false);
  const apiRequest = useApi(setError);
  const { show, setDisplayed } = useToast();

  const onSubmit = async ({ password }) => {
    const res = await apiRequest({
      url: '/auth/reset-password',
      method: 'POST',
      data: { password, confirm_password: password },
      query: { access_token: accessToken },
    });

    if (res.success) {
      setIsSent(true);
      show('Password has been changed successfully.')

      setTimeout(() => {
        setDisplayed(false);
        navigate(ROUTES.LOGIN);
      }, 5000);
    }
  };

  useEffect(() => {
    if (user) {
      navigate(ROUTES.PROJECTS);
    } else {
      if (!accessToken) {
        throw new Error('Page not found');
      }

      apiRequest({
        url: '/auth/reset-password-template',
        method: 'GET',
        query: { access_token: accessToken },
      }).then(({ success }) => {
        if (success) {
          setIsLoading(false);
        }
      });
    }
  }, []);


  return (
    <ModalLikePage title="Password reset" isInitLoading={isLoading}>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Password"
          name="password"
          type="password"
          autoComplete="new-password"
          register={register}
          errors={errors}
          help="Use 8 or more characters with a mix of letters & numbers."
          required="Field is required"
          minLength={8}
        />

        <SubmitButton
          text="Reset password"
          isActive={isValid && !isSent}
          isLoading={isSubmitting}
        />
      </Form>

    </ModalLikePage>
  );
};
