import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from '~/routes';
import { NavLink } from '~/components/NavLink';

const TABS = [
  { title: 'Profile', route: ROUTES.PROFILE },
  { title: 'Notifications', route: ROUTES.NOTIFICATIONS },
  { title: 'Delete account', route: ROUTES.DELETE_ACCOUNT },
]

export const SettingsTabs = ({ activeTab }) => {
  const navigate = useNavigate();

  return (
    <div className="grid grid-cols-3 justify-items-stretch mb-8">
      {TABS.map(({ title, route }, idx) => {
        const isActive = title === activeTab;

        return (
          <NavLink
            key={idx}
            to={!isActive && route}
            className={twMerge(
              'text-center text-xl font-normal text-black visited:text-black active:text-black',
              isActive ? 'border-solid border-b-2 border-t-0 border-x-0 border-gray-700' : 'cursor-pointer text-zinc-700'
            )}
            >{title}</NavLink>
        );
      })}
    </div>
  );
}
