import React, { createContext, useState } from 'react';

export const ToastContext = createContext({
  displayed: false,
  data: {text: '', type: ''},
  setDisplayed: null,
  show: null,
});

export const ToastProvider = ({ children }) => {
  const [displayed, setDisplayed] = useState(false);
  const [data, setData] = useState(() => ({ text: '', type: '' }));

  const show = (text, type = 'success') => {
    if (type === 'danger' && !text) {
      text = 'Something went wrong, please try again a bit later.';
    }
    setData({ text, type });
    setDisplayed(true);
  }

  return (
    <ToastContext.Provider value={{ displayed, data, setDisplayed, show }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return React.useContext(ToastContext);
};
