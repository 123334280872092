import React from 'react';
import { twMerge } from 'tailwind-merge';

import { scrollBar } from './Grid.module.css';

export const Grid = ({ columns, items, label, className, handleActions, onSelect }) => {
  return (
    <div className={className}>
      {Boolean(label) && (
        <label
          className="block mb-1 text-base text-neutral-500/80"
        >{ label }</label>
      )}

      <div
        className={twMerge(
          'relative w-full rounded-xl border border-solid border-neutral-500/25 text-left text-neutral-900',
          // treeClassName
        )}
        // style={style}
        {...handleActions || onSelect ? {
          onClick: handleTreeActions,
        } : {}}
        {...handleActions ? {
          onMouseOver: handleActions && handleToggle,
          onMouseOut: handleActions && handleToggle,
        } : {}}
      >
        <div
          className="flex w-full whitespace-nowrap justify-center items-center rounded-t-xl px-6 py-2 bg-gray-100 text-gray-500"
        >
          {columns.map(({ header, headerClassName, className }, i) => (
            <span className={twMerge('text-gray-500 text-center', headerClassName, className)}>{header}</span>
          ))}
        </div>

        <div className={twMerge('mb-2', scrollBar)}>
          {items.map((item, i) => (
            <Line
              key={i}
              item={item}
              idx={i}
              // click={Boolean(click)}
              controls={Boolean(handleActions)}
              selectable={Boolean(onSelect)}
              columns={columns}
              prev={items[i-1]}
              next={items[i+1]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const Line = ({
  item, next, prev,
  click, controls, selectable,
  columns,
  idx
}) => {
  // const { name, depth, readOnly, selected, color, disabled, type } = item;
  return (
    <div
      className={twMerge(
        'relative px-6 flex whitespace-nowrap py-1',
        'flex justify-center items-center',
        idx % 2 && 'bg-gray-100',
      )}
      data-mouseover
    >
      {/* <span
        className={twMerge(
          'relative flex flex-auto text-ellipsis overflow-hidden whitespace-nowrap',
          click && !selected ? 'cursor-pointer' : '',
          click && selected ? 'bg-slate-300' : '',
        )}
        data-idx={idx}
        data-action="click"
      >
      </span> */}

      {columns.map(({ field, className, formatter, style }, i) => (
        <span
          key={i}
          className={twMerge('overflow-hidden text-ellipsis text-center', className)}
          style={style}
        >
          {item[field] !== undefined ? (formatter ? formatter(item[field]) : item[field]) : ''}
          {i === 0 && controls === true && (
            <span className="controls inline-block md:hidden relative ml-4 mt-0.5 flex gap-1 align-bottom" data-idx={idx}>
              <Edit data-action="edit" className="cursor-pointer" />
              {type !== 'project' && <Archive data-action="archive" className="cursor-pointer" />}
              {type !== 'project' && <Delete data-action="delete" className="cursor-pointer" />}
            </span>
          )}
        </span>
      ))}

      {/* {selectable && (
        <span data-idx={idx} className="ml-4 flex items-start flex-row" data-action="select" data-change-to={!selected}>
          <Switch name={`node-${idx}`} value={Boolean(selected)} disabled={disabled} />
        </span>
      )} */}
    </div>
  );
}
