import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { BaseTree, composeFullTree, FlatTree } from '~/components/Tree';
import { Button, Form, FormField, SubmitButton } from '~/components/Form';
import { dateFormatter } from '~/utils/date';
import { DecisionStatusIcon } from '~/components/DecisionStatusIcon';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';
import { useIndicators } from '~/hooks/useIndicators';
import { useToast } from '~/hooks/useToast';

export const DecisionPage = () => {
  const {
    clearErrors,
    control,
    register,
    handleSubmit,
    formState: {isSubmitting, isValid, errors},
    setError,
    setValue,
    trigger,
    watch,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      importance: 5,
      validation_description: '',
      validation_completed: false,
      validation_expires: '',
      resolution_description: '',
      resolution_applied: '',
    }
  });
 
  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { show } = useToast();
  const {
    addIndicator,
    getNotReadOnly,
    getSelected,
    setReadOnly,
    setSelected,
    updateIndicators
  } = useIndicators(setError);

  const id = Number(useParams().id);
  const projectId = Number(useParams().projectId);
  const projectUrl = ROUTES.PROJECT_ACTIVITIES.replace(':id', projectId);
  const [project, setProject] = useState();
  const [tree, setTree] = useState(() => []);
  const [parent, setParent] = useState();
  const [
    decisionName,
    validationCompleted,
    validationDescription,
    resolutionDescription,
    resolutionApplied,
  ] = watch([
    'name',
    'validation_completed',
    'validation_description',
    'resolution_description',
    'resolution_applied',
  ]);
  const [indicators, setIndicators] = useState(() => []);
  let selectedIndicatorsIds = [];

  useEffect(() => {
    if (!projectId) {
      return;
    }

    (async () => {
      const [
        { success, body: project },
        { success: successTree, body: { tree } },
      ] = await Promise.all([
        apiRequest({ url: `/project/${projectId}` }),
        apiRequest({ url: `/project/${projectId}/tree` }),
      ]);

      if (!success || !successTree) {
        return show('Unknown project', 'danger');
      }

      setProject(project);
      // setReadOnly(project.indicators.items, id);
      const flatIndicators = new FlatTree().reduce(project.indicators.items);
      setIndicators(flatIndicators);
      // tree.children = tree.streams;
      // tree.selected = true;
      // setProjectTree([tree]);
      // const flatTree = new FlatTree().reduce([tree]);

      if (id) {
        const { success, body } = await apiRequest({ url: `/decision/${id}` });
        if (!success) {
          return show('Unknown decision', 'danger');
        }

        // setParent(flatTree.find(node => node.id ===));

        setValue('name', body.name);
        setValue('importance', body.importance)
        setValue('validation_description', body.validation_description ?? '');
        setValue('validation_completed', body.validation_completed);
        if (body.validation_expires) {
          setValue('validation_expires', dateFormatter.format(new Date(body.validation_expires)));
        }
        setValue('resolution_description', body.resolution_description ?? '');
        if (body.resolution_applied) {
          setValue('resolution_applied', dateFormatter.format(new Date(body.resolution_applied)));
        }
        selectedIndicatorsIds = body.connected_indicator_ids ?? [];
        // if (selectedIndicatorsIds.length) {
        //   setSelected(project.indicators.items, selectedIndicatorsIds);
        //   setIndicators(project.indicators.items);
        // }
        // trigger();
      } else {
        // setParent(project);
      }

      const fullTree = JSON.parse(JSON.stringify(tree));
      const flatTree = new FlatTree().reduce([composeFullTree(fullTree)]);
      setTree(flatTree);

    })();

    return () => null;
  }, [projectId]);

  const onSubmit = async ({ name, color, description }, e) => {
    // const selectedIndicators = getSelected(indicators);
    // if (!selectedIndicators.length && !getNotReadOnly(indicators).length) {
    //   setError('indicators', {
    //     message: 'At least one indicator is required',
    //     type: 'manual',
    //   });
    //   return;
    // }

    // const data = { stream_id: id, project_id: projectId, name, color, description, team_size };
    // if (parent?.id && parent?.color) {
    //   data.parent_id = parent.id;
    // }
    // const { success, body } = await apiRequest({
    //   url: '/stream/',
    //   method: id ? 'PUT' : 'POST',
    //   data,
    // });

    // if (!success) {
    //   return;
    // }

    // await updateIndicators(indicators, projectId, id || body.id);

    // const newSelectedIndicatorsIds = selectedIndicators.reduce((acc, ind) => {
    //   acc.add(ind.id);
    //   return acc;
    // }, new Set(selectedIndicatorsIds));

    // if (
    //   newSelectedIndicatorsIds.size !== selectedIndicatorsIds.length ||
    //   selectedIndicatorsIds.some((id) => !newSelectedIndicatorsIds.has(id))
    // ) {
    //   body.stream_id = id || body.id;
    //   body.connected_indicator_ids = Array.from(newSelectedIndicatorsIds);
    //   await apiRequest({
    //     url: '/stream/',
    //     method: 'PUT',
    //     data: body,
    //   });
    // }

    navigate(projectUrl);
  };

  return (
    <ModalLikePage
      title={id ? `Edit decision`: 'New decision'}
      isInitLoading={(id && !decisionName) || (!id && !indicators.length)}
    >

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Decision name"
          name="name"
          placeholder="New decision"
          register={register}
          errors={errors}
          minLength={2}
          maxLength={255}
          required
        />

        <BaseTree
          label="Connections"
          nodes={tree}
          onSelect={(node, value) => setTree((prev) => {
            prev[node.idx].selected = value;
            return prev.slice();
          })}
          // click={(parent) => setParent(parent)}
        />

        <BaseTree
          label="Decision positively affects on"
          nodes={indicators}
          onSelect={(node, value) => setIndicators((prev) => {
            prev[node.idx].selected = value;
            return prev.slice();
          })}
          // setIndicators={setIndicators}
          // clearErrors={() => {
          //   clearErrors('indicators');
          //   trigger();
          // }}
        />
        {Boolean(errors?.indicators) && (
          <div className="text-sm text-red-600 -mt-6" role="alert">
            {errors.indicators.message || errors.indicators.type}
          </div>
        )}

        <FormField
          label="Importance (from 1 to 10)"
          name="importance"
          placeholder="5"
          register={register}
          errors={errors}
          required
        />

        <h1
          className="w-full min-w-[200px] md:min-w-[473px] text-3xl text-neutral-600 text-left"
        >
          Validation (optional)
        </h1>

        <FormField
          label="Hypothesis & Experiment description (or Jira/Confluence URLs)"
          name="validation_description"
          type="textarea"
          rows={5}
          register={register}
          errors={errors}
        />

        <FormField
          label="Validation is completed"
          name="validation_completed"
          type="checkbox"
          control={control}
          errors={errors}
        />

        <FormField
          label={<>Validation expires<br/>(recommended, we will notify you)</>}
          name="IDK"
          type="checkbox"
          control={control}
          errors={errors}
        />

        {validationCompleted && (
          <FormField
            label=""
            name="validation_expires"
            type="datePicker"
            control={control}
            errors={errors}
            className="-mt-5"
          />
        )}

        <h1
          className="w-full min-w-[200px] md:min-w-[473px] text-3xl text-neutral-600 text-left"
        >
          Resolution
        </h1>

        <FormField
          label="Resolution description (or Jira/Confluence URLs)"
          name="resolution_description"
          type="textarea"
          rows={5}
          register={register}
          errors={errors}
        />

        <FormField
          label="Resolution date"
          name="resolution_applied"
          type="datePicker"
          control={control}
          errors={errors}
        />


        <div>
          <div className="mb-2">
            Current status: <DecisionStatusIcon decision={{
              validation_completed: validationCompleted,
              validation_description: validationDescription,
              resolution_description: resolutionDescription,
              resolution_applied: resolutionApplied,
            }} className="ml-2" />
          </div>

          <p>
            Drafts serve as a repository for ideas and raw research,
            ready to be transformed into decisions.
            A decision stays in draft form until it's equipped with a
            Resolution description and a Resolution date.
          </p>
        </div>

        <div className="flex gap-6">
          <div className="flex-1">
            <SubmitButton text={id ? 'Save' : 'Create'} isActive={isValid} isLoading={isSubmitting} />
          </div>
          <div className="flex-1">
            <Button text="Back to project" onClick={() => navigate(projectUrl)} />
          </div>
        </div>

      </Form>

    </ModalLikePage>
  );
};
