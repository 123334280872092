import React from 'react';

import { ModalLikePage } from '~/components/ModalLikePage';
import { SubmitButton } from '~/components/Button';
import { useBack } from '~/hooks/useBack';

export const AboutPage = () => (
  <ModalLikePage title="About">

    <p className="w-full text-left my-8 text-neutral-600">
      blah-blah
    </p>

    <SubmitButton text="Go back" isActive={true} onClick={useBack()} />
  </ModalLikePage>
);
