import React, { useEffect } from 'react';
import { useRouteError } from 'react-router-dom';

import { ModalLikePage } from '~/components/ModalLikePage';

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <ModalLikePage>
      <h1 className="w-full text-3xl text-neutral-600 text-left">
        Oops!
      </h1>

      <p className="w-full text-left mt-8 mb-32 text-neutral-600">
        {error.statusText || error.message}

        {Boolean(error.stack) && import.meta.env.DEV && (
          <pre className="mt-2">{error.stack}</pre>
        )}
      </p>

    </ModalLikePage>
  );
};
