import React from 'react';
import { twMerge } from 'tailwind-merge';

import Archive from './assets/Archive.svg?react';
import { DecisionStatusIcon } from '../DecisionStatusIcon';
import Delete from './assets/Delete.svg?react';
import Edit from './assets/Edit.svg?react';
import { Switch } from '../Switch';

import { scrollBar } from './BaseTree.module.css';

/**
 * Searches DOM node with data-action attribute climbing up the ancestral DOM tree.
 * @param {HTMLElement} node Initial DOM node (e.g. from event's target).
 * @param {string}      dataAttr
 * @returns {HTMLElement | null}
 */
const searchActionNode = (node, dataAttr = 'action') => {
  if (node.dataset?.[dataAttr]) {
    return node;
  }

  if (node.parentNode) {
    return searchActionNode(node.parentNode, dataAttr)
  }

  return null;
}

/**
 * @typedef {import('./FlatTree').AbstractNode} AbstractNode
 */

/**
 * @type React.FC<{ label: string, nodes: AbstractNode[], onSelect: () => void }>
 */
export const BaseTree = ({ label, nodes, selectTypes, onSelect, handleActions, className, style, treeClassName, gridHeaders, gridColumns }) => {
  if (!nodes || !Array.isArray(nodes)) {
    throw new Error('BaseTree requires `nodes` parameter with tree flattened to array');
  }

  const handleTreeActions = (e) => {
    const node = searchActionNode(e.target);
    if (!node) {
      return;
    }
    // e.stopPropagation();
    e.preventDefault();

    const { dataset: { action } } = node;
    const currentIdx = Number(node.parentNode.dataset?.idx || node.dataset?.idx);
    const current = nodes[currentIdx];
    if (!current) {
      return;
    }
    // console.log(current, action)

    // const prev = nodes[currentIdx - 1] ?? null;
    // const next = nodes[currentIdx + 1] ?? null;

    if (action === 'select' && onSelect && (!selectTypes || selectTypes.has(current.type))) {
      onSelect(current, node.dataset.changeTo === 'true');
    } else if (handleActions) {
      handleActions(action, current, node.dataset);
    }
  }

  const handleToggle = (e) => {
    e.stopPropagation();
    e.preventDefault();

    const node = searchActionNode(e.target, 'mouseover');
    if (!node) {
      return;
    }

    const controlsNode = node.querySelector('.controls');
    if (controlsNode && e.type === 'mouseover') {
      controlsNode.classList.remove('md:hidden');
    } else if (controlsNode && e.type === 'mouseout') {
      controlsNode.classList.add('md:hidden');
    }
  };

  return (
    <div className={className}>
      {Boolean(label) && (
        <label
          className="block mb-1 text-base text-neutral-500/80"
        >{ label }</label>
      )}

      <div
        className={twMerge(
          'relative w-full rounded-xl border border-solid border-neutral-500/25 text-left text-neutral-900',
        )}
        {...handleActions || onSelect ? {
          onClick: handleTreeActions,
        } : {}}
        {...handleActions ? {
          onMouseOver: handleActions && handleToggle,
          onMouseOut: handleActions && handleToggle,
        } : {}}
      >
        {Boolean(gridHeaders) && (
          <div key="0" className="flex w-full whitespace-nowrap justify-center items-center px-6 py-2 rounded-t-xl bg-gray-100 text-gray-500">
            {gridHeaders.map((header, i) => (
              <span key={i} className={twMerge(i ? 'text-center' : 'flex-auto', gridColumns[i-1]?.className)}>{header}</span>
          ))}
          </div>
        )}
        <div style={style} className={twMerge(treeClassName, gridColumns && scrollBar, gridColumns && 'mb-2')}>
          {nodes.map((node, i) => (
            <Node
              key={i}
              node={node}
              // click={Boolean(click)}
              controls={Boolean(handleActions)}
              selectable={Boolean(onSelect) && (!selectTypes || selectTypes.has(node.type))}
              gridColumns={gridColumns}
              prev={nodes[i-1]}
              next={nodes[i+1]}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

const Node = ({
  node,
  prev, next,
  click, controls, selectable,
  gridColumns,
}) => {
  const { idx, prevSiblingIdx, name, depth, readOnly, selected, color, disabled, type } = node;
  return (
    <div
      className={twMerge(
        'relative px-6 flex whitespace-nowrap py-1',
        !next && 'pb-4',
        !prev && (gridColumns ? 'pt-2' : 'pt-4'),
        type === 'decision' && idx % 2 && 'bg-gray-100',
      )}
      data-mouseover
    >
      {new Array(depth).fill(true).map((_, i) => {
        const nodeHeight = 32;
        const verticalLineHeight = Math.floor((prevSiblingIdx === null ? 0.8 : idx - prevSiblingIdx) * nodeHeight);
        const leftIndent = i * 6 * 4 + 24;

        return (
          <React.Fragment key={i}>
            {/* Vertical line */}
            <span
              className={twMerge(
                'block absolute w-5',
                i === depth - 1 ? ' border-dashed border-l-2 border-l-gray-600/50 z-0' : '',
              )}
              style={{
                left: `${leftIndent}px`,
                height: `${ verticalLineHeight}px`,
                marginTop: `${-1 * verticalLineHeight + 13}px`,
              }}
            ></span>
            {/* Horizontal line */}
            <span
              className={twMerge(
                'block absolute w-5 h-5 mt-[11px]',
                i === depth - 1 ? ' border-dashed border-t-2 border-t-gray-600/50 z-0' : '',
              )}
              style={{
                left: `${leftIndent}px`,
              }}
            ></span>
          </React.Fragment>
        );
      })}

      <span
        className={twMerge(
          'relative flex flex-auto text-ellipsis overflow-hidden whitespace-nowrap',
          click && !selected ? 'cursor-pointer' : '',
          click && selected ? 'bg-slate-300' : '',
        )}
        style={{
          marginLeft: `${depth * 6 * 4}px`,
          color: color ? color : 'inherit',
          opacity: disabled ? '0.5' : 'inherit',
        }}
        data-idx={idx}
        data-action="click"
      >
        {node.type === 'decision' && <DecisionStatusIcon decision={node} />}
        {name}

        {!readOnly && controls === true && (
          <span className="controls inline-block md:hidden relative ml-4 mt-0.5 flex gap-1 align-bottom" data-idx={idx}>
            <Edit data-action="edit" className="cursor-pointer" />
            {type !== 'project' && <Archive data-action="archive" className="cursor-pointer" />}
            {type !== 'project' && <Delete data-action="delete" className="cursor-pointer" />}
          </span>
        )}
      </span>

      {type === 'decision' && Boolean(gridColumns) && (
        <span className="flex justify-center items-center text-gray-500">
          {gridColumns?.map(({ field, className, formatter }, i) => (
            <span key={i} className={twMerge('text-center', className)}>
              {node[field] !== undefined ? (formatter ? formatter(node[field]) : node[field]) : ''}
            </span>
          ))}
        </span>
      )}

      {selectable && (
        <span data-idx={idx} className="ml-4 flex items-start flex-row" data-action="select" data-change-to={!selected}>
          <Switch name={`node-${idx}`} value={Boolean(selected)} disabled={disabled} />
        </span>
      )}
    </div>
  );
}
