import { useNavigate } from 'react-router-dom';

import { apiClient } from '~/api';
import { ROUTES } from '~/routes';
import { useAuth } from './useAuth';
import { useToast } from './useToast';

export const useApi = (setFieldError) => {
  const { displayed, setDisplayed, show } = useToast();
  const { logout } = useAuth();
  const navigate = useNavigate();

  /**
   * @param {{
   *   url: string,
   *   method: string,
   *   headers: Record<string, string>,
   *   data: Record<string, any>,
   * }} params
   * @returns {Promise<{ success: bool, body?: Record<string, any> }>}
   */
  return async (params) => {
    if (displayed) {
      setDisplayed(false);
    }

    const { body, status, success } = await apiClient.request(params);

    if (success) {
      return { success, body };
    }

    if(status === 403 && body.detail === 'Invalid token or expired token.') {
      logout();
      navigate(ROUTES.LOGIN);
      return;
    }

    if (body?.message || typeof body.message === 'string') {
      show(body.message, 'danger');

    } else if (!body?.detail || typeof body.detail === 'string') {
      show(body?.detail ?? '', 'danger');

    } else if (Array.isArray(body.detail)) {
      for (const err of body.detail) {
        if (err?.loc?.length && err.msg && setFieldError) {
          setFieldError(err.loc[err.loc.length - 1], { type: 'manual', message: err.msg });
        }
      }
    } else {
      show('', 'danger');
    }

    return { success, body };
  }
};
