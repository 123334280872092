import React from 'react';

import { ModalLikePage } from '~/components/ModalLikePage';
import { SubmitButton } from '~/components/Button';
import { useBack } from '~/hooks/useBack';

export const ContactsPage = () => (
  <ModalLikePage>
    <h1 className="w-full text-3xl text-neutral-600 text-left">
      Contacts
    </h1>

    <div className="w-full text-left my-8 text-neutral-600 flex-col space-y-4">
      <h2>Reach Out Anytime</h2>
      <p>
        For all inquiries, thoughts, or suggestions, don't hesitate to contact us at hello@growthclue.com.
        Our dedicated team is committed to providing you with timely and helpful responses, ensuring your
        experience with GrowthClue is seamless and productive.
      </p>

      <h2>Your Voice Matters</h2>
      <p>
        Your insights and experiences drive our innovation.
        We're continuously improving GrowthClue to better meet your needs as a product manager.
        Every email is an opportunity for us to learn more about what matters to you and
        how we can enhance your decision-making process.
      </p>
    </div>

    <SubmitButton text="Go back" isActive={true} onClick={useBack()} />
  </ModalLikePage>
);
