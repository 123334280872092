import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';

import { SettingsTabs } from './SettingsTabs';

export const DeleteAccountPage = () => {
  const {
    control,
    formState: {isSubmitting, isValid, errors},
    handleSubmit,
    register,
    setError,
  } = useForm({
    defaultValues: {
      agree: false,
      password: '',
    }
  });

  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { logout } = useAuth();

  const onSubmit = async (data) => {
    const res = await apiRequest({
      url: `/user/`,
      method: 'DELETE',
      data,
    });

    if (res.success) {
      logout();
      navigate(ROUTES.HOME);
    }
  };

  return (
    <ModalLikePage title="Settings">

      <SettingsTabs activeTab="Delete account" />

      <p className="w-full text-left mb-6">
        Enter your password if you want to delete this
        <br/>
        account completely.
      </p>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Current password"
          name="password"
          type="password"
          autoComplete="current-password"
          register={register}
          errors={errors}
          minLength={8}
          required
        />

        <FormField
          label="I understand that I will lose all data"
          name="agree"
          type="checkbox"
          control={control}
          errors={errors}
          required
        />

        <SubmitButton
          text="Delete this account and all data"
          className="h-32 md:h-16"
          isActive={isValid}
          isLoading={isSubmitting}
          isDanger
        />

      </Form>

    </ModalLikePage>
  );
}
