import React, { forwardRef, useMemo, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Loader } from './Loader';

export const Button = ({ text, className, ...props }) => {
  return (
    <button
      type="button"
      className={twMerge(
        'bg-neutral-950 w-full h-16 py-4 flex justify-center items-center self-stretch border-0 rounded-full text-white text-xl',
        className
      )}
      {...props}
    >
      {text}
    </button>
  );
};

export const SubmitButton = ({ text, isDanger, isActive, isLoading, className, ...props }) => {
  return (
    <button
      type="submit"
      className={twMerge(
        isDanger ? 'bg-rose-600 disabled:bg-rose-600/25 hover:bg-rose-500' : 'bg-neutral-950 disabled:bg-neutral-950/25 hover:bg-neutral-800',
        'w-full h-16 py-4 flex justify-center items-center self-stretch border-0 rounded-full text-white text-xl',
        className,
      )}
      disabled={isActive === false || isLoading}
      {...props}
    >
      {isLoading && <Loader className="absolute text-center" />}
      {text}
    </button>
  );
};
