import React from 'react';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';

import { cutString } from '~/utils/string';
import { Footer } from '~/components/Footer';
import { Header } from '~/components/Header';
import { Loader } from '~/components/Loader';
import { ROUTES } from '~/routes';
import { Toast } from '~/components/Toast';

export const ProjectLayoutPage = ({ children, isLoading, menu, project, title }) => {
  const navigate = useNavigate();

  if (isLoading || !project) {
    return (
      <div className="min-h-screen min-w-screen flex flex-col place-content-between">
        <Header />
        {isLoading && (<div className="h-full text-center my-20"><Loader /></div>)}
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen min-w-screen flex flex-col">
      <Header title={title} />

      <div className="min-w-full min-h-full flex px-11 pt-6 pb-11">
        <Toast />

        <div className="flex-1 mr-11">

          <div className="flex gap-8 mr-16">
            <h1 className="flex-1 text-cyan-400 text-3xl font-medium">{cutString(project?.name, 20, false)}</h1>
            {[
              { title: 'Dashboard', route: ROUTES.PROJECT_DASHBOARD.replace(':id', project.id) },
              { title: 'Activities', route: ROUTES.PROJECT_ACTIVITIES.replace(':id', project.id) },
            ].map((page, i) => {
              const isActive = title.toLowerCase().includes(page.title.toLowerCase());
              return (
                <div
                  key={i}
                  className={twMerge(
                    'flex-none px-8 text-3xl font-medium',
                    isActive ? 'border-solid border-b-2 border-gray-700' : 'text-gray-400 cursor-pointer',
                  )}
                  onClick={() => !isActive && navigate(page.route)}
                >{page.title}</div>
              );
            })}
          </div>

          {children}
        </div>

        <div className="flex-none w-[371px]">
          {menu}
        </div>

      </div>

      <Footer />
    </div>
  );
}
