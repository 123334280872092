import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { useToast } from '~/hooks/useToast';

export const NavLink = ({ to, className, onClick, children }) => {
  const { displayed, setDisplayed } = useToast();

  return (
    <Link
      to={to}
      preventScrollReset={false}
      className={twMerge('text-white visited:text-white active:text-white', className)}
      onClick={() => {
        onClick?.();
        displayed && setDisplayed(false);
      }}
    >
      {children}
    </Link>
  );
};
