import React, { StrictMode } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { AboutPage } from './pages/AboutPage';
import { AuthProvider } from './hooks/useAuth';
import { ContactsPage } from './pages/ContactsPage';
import { CookiePolicyPage } from './pages/CookiePolicyPage';
import * as decisions from './pages/decisions';
import { EmailVerificationPage } from './pages/EmailVerificationPage';
import { ErrorPage } from './pages/ErrorPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { LoginPage } from './pages/LoginPage';
import { MainPage } from './pages/main';
import { PrivacyPage } from '~/pages/PrivacyPage';
import * as projects from './pages/projects';
import { ProtectedRoute } from './components/ProtectedRoute';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { ROUTES } from './routes';
import * as settings from './pages/settings';
import { SignupPage, SignupSuccessPage } from './pages/SignupPage';
import * as streams from './pages/streams';
import './styles/app.css';
import * as tasks from './pages/tasks';
import { TeamSizePage } from '~/pages/TeamSizePage';
import { TermsPage } from '~/pages/TermsPage';
import { ToastProvider } from './hooks/useToast';

const router = createBrowserRouter([
  {
    path: ROUTES.HOME,
    element: <MainPage />,
  },
  {
    path: ROUTES.LOGIN,
    element: <LoginPage />,
  },
  {
    path: ROUTES.SIGNUP,
    element: <SignupPage />,
  },
  {
    path: ROUTES.SIGNUP_SUCCESS,
    element: <SignupSuccessPage />,
  },
  {
    path: ROUTES.EMAIL_VERIFICATION,
    element: <EmailVerificationPage />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPasswordPage />,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPasswordPage />,
  },

  {
    path: ROUTES.PROFILE,
    element: <ProtectedRoute><settings.ProfilePage /></ProtectedRoute>,
  },
  {
    path: ROUTES.NOTIFICATIONS,
    element: <ProtectedRoute><settings.NotificationsPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.DELETE_ACCOUNT,
    element: <ProtectedRoute><settings.DeleteAccountPage /></ProtectedRoute>,
  },

  {
    path: ROUTES.PROJECT_NEW,
    element: <ProtectedRoute><projects.ProjectPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.PROJECT_DELETE,
    element: <ProtectedRoute><projects.ProjectDeletePage /></ProtectedRoute>,
  },
  {
    path: ROUTES.PROJECT_DASHBOARD,
    element: <ProtectedRoute><projects.ProjectDashboardPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.PROJECT_ACTIVITIES,
    element: <ProtectedRoute><projects.ProjectActivitiesPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.PROJECT,
    element: <ProtectedRoute><projects.ProjectPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.PROJECTS,
    element: <ProtectedRoute><projects.ProjectsPage /></ProtectedRoute>,
  },

  {
    path: ROUTES.STREAM_NEW,
    element: <ProtectedRoute><streams.StreamPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.STREAM_DELETE,
    element: <ProtectedRoute><streams.StreamDeletePage /></ProtectedRoute>,
  },
  {
    path: ROUTES.STREAM,
    element: <ProtectedRoute><streams.StreamPage /></ProtectedRoute>,
  },

  {
    path: ROUTES.DECISION_NEW,
    element: <ProtectedRoute><decisions.DecisionPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.DECISION_DELETE,
    element: <ProtectedRoute><decisions.DecisionDeletePage /></ProtectedRoute>,
  },
  {
    path: ROUTES.DECISION,
    element: <ProtectedRoute><decisions.DecisionPage /></ProtectedRoute>,
  },

  {
    path: ROUTES.TASK_NEW,
    element: <ProtectedRoute><tasks.TaskPage /></ProtectedRoute>,
  },
  {
    path: ROUTES.TASK_DELETE,
    element: <ProtectedRoute><tasks.TaskDeletePage /></ProtectedRoute>,
  },
  {
    path: ROUTES.TASK,
    element: <ProtectedRoute><tasks.TaskPage /></ProtectedRoute>,
  },

  {
    path: ROUTES.TEAM_SIZE_RECORD_NEW,
    element: <ProtectedRoute><TeamSizePage /></ProtectedRoute>,
  },

  {
    path: ROUTES.ABOUT,
    element: <AboutPage />,
  },
  {
    path: ROUTES.PRIVACY,
    element: <PrivacyPage />,
  },
  {
    path: ROUTES.COOKIE,
    element: <CookiePolicyPage />,
  },
  {
    path: ROUTES.TERMS,
    element: <TermsPage />,
  },
  {
    path: ROUTES.CONTACTS,
    element: <ContactsPage />,
  },
].map((r) => {
  r.errorElement = <ErrorPage />;
  return r;
}));

export const App = () => (
  <StrictMode>
    <AuthProvider>
      <ToastProvider>
        <RouterProvider router={router} />
      </ToastProvider>
    </AuthProvider>
  </StrictMode>
);
