import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '../routes';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';
import { useLocalStorage } from '~/hooks/useLocalStorage';
import { useToast } from '~/hooks/useToast';

export const LoginPage = () => {
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    setError,
    setFocus,
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  });
  const { login, user } = useAuth();
  const navigate = useNavigate();
  const apiRequest = useApi(setError);
  const { tutorialProject, removeTutorialProject } = useLocalStorage('tutorialProject', null);

  const onSubmit = async ({ email, password }) => {
    const res = await apiRequest({
      url: '/auth/login',
      method: 'POST',
      data: { email, password },
    });

    if (res.success) {
      login(res.body);
      if (tutorialProject) {
        await apiRequest({ url: '/auth/create-tutorial', method: 'POST' });
        removeTutorialProject();
      }
      navigate(ROUTES.PROJECTS);
    }
  };

  useEffect(() => {
    if (user) {
      navigate(ROUTES.PROJECTS);
    } else {
      setFocus('email');
    }
  }, []);


  return (
    <ModalLikePage title="Login" subheader={
      <p className="w-full text-left mt-2 text-neutral-600">
        Don’t have an account?{' '}
        <Link to={ROUTES.SIGNUP} className="underline text-neutral-600 visited:text-neutral-600">
          Sign up
        </Link>
      </p>
    }>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          label="Email address"
          name="email"
          type="email"
          autoComplete="email"
          register={register}
          errors={errors}
          required
        />

        <FormField
          label="Password"
          name="password"
          type="password"
          autoComplete="current-password"
          register={register}
          errors={errors}
          minLength={8}
          required
        />

        <p className="-mt-6 text-right text-sm">
          <Link
            to={ROUTES.FORGOT_PASSWORD}
            className="underline text-neutral-600 visited:text-neutral-600"
          >Forgot your password?</Link>
        </p>

        <SubmitButton text="Log in" isActive={isValid} isLoading={isSubmitting} />

      </Form>

      <p className="w-full text-left mt-6 text-neutral-600">
        Don’t have an account?{' '}
        <Link to={ROUTES.SIGNUP} className="underline text-neutral-600 visited:text-neutral-600">
          Sign up
        </Link>
      </p>

    </ModalLikePage>
  );
};
