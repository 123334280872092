export const composeFullTree = (node) => {
  if (!node.children) {
    node.children = [];
  }

  if (node.streams) {
    node.type = 'project';
    if (node.streams?.length) {
      node.children = node.children.concat(node.streams);
    }

  } else if (node.color) {
    node.type = 'stream';
    if (node.decisions?.length) {
      node.children = node.children.concat(node.decisions);
    }
    if (node.tasks?.length) {
      node.children = node.children.concat(node.tasks);
    }

  } else if (node.importance) {
    node.type = 'decision';
    if (node.tasks?.length) {
      node.children = node.children.concat(node.tasks);
    }

  } else {
    node.type = 'task';
  }

  if (node.children.length) {
    for (const child of node.children) {
      composeFullTree(child);
    }
  }

  return node;
};
