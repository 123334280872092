import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Form, FormField, SubmitButton } from '~/components/Form';
import { ModalLikePage } from '~/components/ModalLikePage';
import { ROUTES } from '~/routes';
import { useApi } from '~/hooks/useApi';
import { useAuth } from '~/hooks/useAuth';

export const ForgotPasswordPage = () => {
  const {
    formState: { errors, isSubmitting, isValid },
    handleSubmit,
    register,
    setError,
    setFocus,
  } = useForm({ defaultValues: { email: '' } });

  const navigate = useNavigate();
  const { user } = useAuth();
  const [isSent, setIsSent] = useState(false);
  const apiRequest = useApi(setError);

  const onSubmit = async ({ email }) => {
    const res = await apiRequest({
      url: '/auth/forgot-password',
      method: 'POST',
      query: { email },
    });

    if (res.success) {
      setIsSent(true);
    }
  };

  useEffect(() => {
    if (user) {
      navigate(ROUTES.PROJECTS);
    } else {
      setFocus('email');
    }
  }, []);


  return (
    <ModalLikePage title={isSent ? 'Check your email' : 'Forgot password?'}>

      {isSent ? (
        <p className="text-lg">We sent you instructions on how to reset your password.</p>
      ) : (
        <>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormField
              label="Your email address"
              name="email"
              type="email"
              autoComplete="email"
              register={register}
              errors={errors}
              required
            />

            <SubmitButton text="Reset password" isActive={isValid} isLoading={isSubmitting} />

          </Form>
        </>
      )}

    </ModalLikePage>
  );
};
