import React, { useEffect } from 'react';

import { Footer } from '../Footer';
import { Header } from '../Header';

import BgSrc from './assets/bg.jpg?url=';
import { Loader } from '../Loader';
import { Toast } from '../Toast';

export const ModalLikePage = ({ title, subheader, isInitLoading, children }) => {
  return (
    <div className="min-h-screen min-w-screen flex flex-col place-content-between">
      <Header title={title} />

      <div
        className="text-center h-full flex-1 min-h-full min-w-[300px] md:min-w-[473px]"
        style={{
          background: `url(${BgSrc}) center / cover no-repeat, lightgray 50% / cover no-repeat`,
          backdropFilter: 'blur(62px)',
        }}>

        <div className="inline-flex items-center m-4 p-8 md:px-32 md:py-24 md:my-10 rounded-3xl bg-white flex flex-col flex-end">
          <Toast />
          {Boolean(title) && (
            <>
              <h1 className="w-full min-w-[200px] md:min-w-[473px] text-3xl text-neutral-600 text-left">
                {title}
              </h1>
              {subheader}
            </>
          )}

          <div className="w-full mt-8 w-[318px] md:w-[473px] text-left">
            {isInitLoading && <div className="text-center"><Loader /></div>}
            {!isInitLoading && children}
          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
}
