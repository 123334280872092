export { BaseTree } from './BaseTree';
export { composeFullTree } from './utils';
export { FlatTree } from './FlatTree';

import React from 'react';
import { twMerge } from 'tailwind-merge';

import ArrowDown from './assets/ArrowDown.svg?react';
import ArrowLeft from './assets/ArrowLeft.svg?react';
import ArrowRight from './assets/ArrowRight.svg?react';
import ArrowUp from './assets/ArrowUp.svg?react';
import Delete from './assets/Delete.svg?react';
import { FlatTree } from './FlatTree';
import { Switch } from '../Switch';

const searchActionNode = (node) => {
  if (node.dataset?.action) {
    return node;
  }

  if (node.parentNode) {
    return searchActionNode(node.parentNode)
  }

  return null;
}

const insertAfter = (newItem, items, afterItem) => {
  const idx = items.indexOf(afterItem);
  return items.slice(0, idx + 1).concat([newItem]).concat(items.slice(idx + 1));
}

const remove = (items, removedItem) => {
  const idx = items.indexOf(removedItem);
  return items.slice(0, idx).concat(items.slice(idx + 1));
}

export const Tree = ({ label, controls, click, data, onSelect, setIndicators, clearErrors }) => {
  const nodes = new FlatTree().reduce(data);

  const manageTree = (e) => {
    const node = searchActionNode(e.target);
    if (!node) {
      return;
    }
    // e.stopPropagation();
    e.preventDefault();

    const { id, dataset: { action } } = node;
    const currentIdx = Number(node.parentNode.dataset?.idx || node.dataset?.idx);
    const current = nodes[currentIdx];
    if (!current) {
      return;
    }
    console.log(current, action)

    const prev = nodes[currentIdx - 1] ?? null;
    const next = nodes[currentIdx + 1] ?? null;

    let changed = false;
    if (action === 'up' && prev?.depth === current.depth) {
      const siblings = current.parent ? current.parent.children : data;
      const pos = siblings.indexOf(current);
      siblings[pos] = prev;
      siblings[pos - 1] = current;

      const weight = current.order_weight;
      current.order_weight = prev.order_weight;
      prev.order_weight = weight;
      current.weightChanged = true;
      prev.weightChanged = true;
      if (current.order_weight >= prev.order_weight) {
        prev.order_weight += 5
      }
      changed = true;

    } else if (action === 'down' && next?.depth === current.depth) {
      const siblings = current.parent ? current.parent.children : data;
      const pos = siblings.indexOf(current);
      siblings[pos] = next;
      siblings[pos + 1] = current;

      const weight = current.order_weight;
      current.order_weight = next.order_weight;
      next.order_weight = weight;
      current.weightChanged = true;
      next.weightChanged = true;
      if (current.order_weight < next.order_weight) {
        next.order_weight -= 5
      }
      changed = true;

    } else if (action === 'left' && current.parent) {
      const newParent = current.parent.parent;
      current.parent.children = current.parent.children.filter(n => n.id !== current.id);
      if (newParent) {
        newParent.children = insertAfter(current, newParent.children, current.parent);
      } else {
        data.push(current); // Move to the root level.
      }
      changed = true;

    } else if (action === 'right' && prev?.depth >= current.depth) {
      for (let i = currentIdx - 1; i >= 0; i--) {
        if (nodes[i].depth !== current.depth) {
          continue;
        }
        if (current.parent) {
          current.parent.children = current.parent.children.filter(n => n.id !== current.id);
        } else {
          data = remove(data, current); // Remove from the root level.
        }
        const newParent = nodes[i];
        newParent.children.push(current);
        changed = true;
        break;
      }

    } else if (action === 'delete' && next?.parent?.id !== current.id) {
      changed = true;
      current.deleted = true;

    } else if (action === 'select' && current.readOnly) {
      if (onSelect) {
        onSelect(current);
      } else {
        current.selected = !current.selected;
        changed = true;
      }
    } else if (action === 'click' && click && !current.selected) {
      for (const node of nodes) {
        delete node.selected;
      }
      current.selected = true;
      click(current);
    }

    if (changed) {
      current.changed = true;
      setIndicators?.(() => [...data]);
      clearErrors?.();
    }
  }

  return (
    <div>
      <label
        className="block mb-1 text-base text-neutral-500/80"
      >{ label }</label>

      <div
        className="w-full h-full rounded-xl border border-solid px-6 py-4 border-neutral-500/25 text-left text-neutral-900"
        onClick={manageTree}
      >
        {nodes.map((node, i) => (
          <Node
            key={i}
            node={node}
            click={Boolean(click)}
            controls={controls}
            selectable={Boolean(onSelect)}
            prev={nodes[i-1]}
            next={nodes[i+1]}
          />
        ))}
      </div>
    </div>
  );
}

const Node = ({
  node: { id, idx, name, parent, depth, readOnly, selected, color },
  prev, next,
  click, controls, selectable,
}) => {
  return (
    <div className={`relative flex ${next ? 'pb-2' : ''}`}>
      {new Array(depth).fill(true).map((_, i) => (
        <React.Fragment key={i}>
          {/* Vertical line */}
          <span
            className={
              'block absolute w-5' +
              (i === depth - 1 && ' border-dashed border-l-2 border-l-slate-600')
            }
            style={{
              left: `${i * 6 * 4}px`,
              height: `${(idx - parent.idx) * 27}px`,
              marginTop: `${-1 * (idx - parent.idx) * 27 + 13}px`,
            }}
          ></span>
          {/* Horizontal line */}
          <span
            className={
              'block absolute w-5 h-5 mt-[11px]' +
              (i === depth - 1 && ' border-dashed border-t-2 border-t-slate-600')
            }
            style={{
              left: `${i * 6 * 4}px`,
            }}></span>
        </React.Fragment>
      ))}

      <span
        className={twMerge(
          'relative',
          click && !selected ? 'cursor-pointer' : '',
          click && selected ? 'bg-slate-300' : '',
        )}
        style={{
          marginLeft: `${depth * 6 * 4}px`,
          color: color ? color : 'inherit',
        }}
        data-idx={idx}
        data-action="click"
      >
        {name}
      </span>

      {!readOnly && controls !== false && (
        <span className="relative ml-4 mt-0.5 flex gap-1 align-bottom" data-idx={idx}>
          <ArrowUp
            data-action="up"
            className={prev?.depth === depth ? '' : 'opacity-25'}
          />
          <ArrowDown
            data-action="down"
            className={next?.depth === depth ? '' : 'opacity-25'}
          />
          <ArrowLeft
            data-action="left"
            className={parent ? '' : 'opacity-25'}
          />
          <ArrowRight
            data-action="right"
            className={prev && prev.depth >= depth ? 'cursor-pointer' : 'opacity-25'} 
          />
          <Delete
            data-action="delete"
            className={!next || next?.parent?.id !== id ? 'cursor-pointer' : 'opacity-25'} 
          />
        </span>
      )}

      {(readOnly && controls !== false) || selectable && (
        <span data-idx={idx} className="absolute right-0 flex items-start flex-row" data-action="select" data-change-to={!selected}>
          <Switch name={`node-${idx}`} value={Boolean(selected)} />
        </span>
      )}
    </div>
  );
}
