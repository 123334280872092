import React from 'react';

export const TextArea = ({ name, placeholder, defaultValue, errors, register, rules, ...props }) => (
  <textarea
    className="w-full h-auto rounded-xl border border-solid px-6 py-4 border-neutral-500/25 text-neutral-900"
    id={`${name}__field`}
    aria-invalid={errors[name] ? 'true' : 'false'}
    {...props}
    {...register(name, rules)}
  >{defaultValue}</textarea>
);
