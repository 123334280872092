/**
 * @typedef AbstractNode
 * @property {Number}         id           ID of node's entity (from API).
 * @property {Number}         depth        Depth of the node in the tree (starting with `0`), filled by FlatTree.reduce.
 * @property {Number?}        order_weight Node's weight for sorting nodes with the same parent and depth.
 * @property {AbstractNode[]} children     Node's children.
 */

export class FlatTree {
  constructor({ readOnly } = { readOnly: false }) {
    this.newNodesCounter = 1;
    this.currentDepth = 0;
    this.readOnly = readOnly;
    this.nodes = [];
  }

  /**
   * Transforms tree like
   *   {id: 1, children: [{id: 2, children: [{id: 3, children: [] }] }] }
   * to [
   *   {id: 1, depth: 0, children: ...},
   *   {id: 2, depth: 1, children: ...},
   *   {id: 3, depth: 2, children: ...},
   * ]
   *
   * @param {{ id: Number, order_weight?: Number, children: any[] }} tree 
   * @param {AbstractNode | null} parent 
   * @returns {AbstractNode[]}
   */
  reduce(tree, parent = null) {
    tree?.sort((a, b) => (a.order_weight ?? 0) - (b.order_weight ?? 0));

    let prevSiblingIdx = null;
    for (const node of tree) {
      if (!node.id) {
        node.id = `new_${this.newNodesCounter++}`;
        node.children = [];
      } else if (node.deleted) {
        continue;
      }

      if (this.readOnly) {
        node.readOnly = true;
      }
      node.depth = this.currentDepth;
      node.parent = parent;
      node.idx = this.nodes.length;
      node.prevSiblingIdx = prevSiblingIdx;
      this.nodes.push(node);

      if (node.children?.length) {
        this.currentDepth += 1;
        this.reduce(node.children, node);
        this.currentDepth -= 1;
      }

      prevSiblingIdx = node.idx;
    }

    return this.nodes;
  }

  push(item, to = null) {
    this.nodes.push({
      depth: to ? to.depth + 1 : 0,
      parent: to,
      idx: this.nodes.length,
      prevSiblingIdx: this.nodes.length ? this.nodes[this.nodes.length - 1] : null,
      id: `new_${this.newNodesCounter++}`,
      ...item,
    });
  }
}
