import { useNavigate } from 'react-router-dom';

export function useBack () {
  const navigate = useNavigate();

  return () => {
    navigate(window.history.state?.idx > 0 ? -1 : '/');
    console.log(window.history.state?.idx)
  };
};
