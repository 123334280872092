import React from 'react';

import classes from './Switch.module.css'

export const Switch = ({ name, value, disabled, onChange }) => {
  return (
    <>
      <input
        id={`${name}__field`}
        type="checkbox"
        className={classes.reactSwitchCheckbox}
        onChange={onChange}
        checked={value}
        disabled={disabled}
      />
      <label
        className={classes.reactSwitchLabel + (value ? ' bg-purple-600' : '') + (disabled ? 'dak' : '')}
        htmlFor={`${name}__field`}
      >
        <span className={classes.reactSwitchButton} />
      </label>
    </>
  );
};
