import React from 'react';
import { Controller } from 'react-hook-form';
import ReactSelect from 'react-select';
import { twMerge } from 'tailwind-merge';

const SelectField = ({ options, ...props }) => (
  <ReactSelect
    classNames={{
      container: (state) => 'w-full',
      control: (state) => twMerge(
        'w-full h-14 rounded-xl border border-solid',
        state.isFocused ? 'border-red-600' : 'border-neutral-500/25'
      ),
      valueContainer: (state) => 'px-6 py-4 text-left',
      option: (state) => 'text-left',
    }}
    {...props}
    options={options}
  />
);

export const Select = ({ name, control, options, rules, ...props }) => {
  if (!control) {
    return <SelectField options={options} {...props} />
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <SelectField {...field} options={options} />}
    />
  );
};
