import { monthFormatter } from '~/utils/date';

export const getFilters = (streams, aggregationPeriod = null, projectId = null) => {
  const filters = streams.reduce(
    (filters, stream) => {
      if (Number(stream.id) == stream.id) {
        if (stream.selected) {
          filters.streamIds.push(stream.id);
        }
      } else if (stream.selected) {
        filters.params[stream.id] = true;
      }

      return filters;
    }, {streamIds: [], params: {}}
  );

  return {
    ...filters,
    hasFilters: Boolean(filters.streamIds.length || Object.keys(filters.params).length),
    query: `?stream_ids=${filters.streamIds.join(',')}` +
      Object.keys(filters.params).reduce((str, param) => str += `&${param}=${filters.params[param]}`,'') +
      (aggregationPeriod !== null ? `&aggregation_period=${aggregationPeriod}` : '') +
      (projectId !== null ? `&project_id=${projectId}` : ''),
  };
};

/** @typedef StrStreamId string */
/** @typedef  string 2024,01*/

/** @typedef StrStreamId string */
/** @typedef  string 2024,01*/

/**
 * 
 * @param {Record<YearMonthDate, { streams: Record<StrStreamId, { validated_count: number, count: number}>}>} data
 * @param {Record<StrStreamId, { name: string, color: string }>} streamsMap
 * @returns {Array<Record<string, { name: string, value: number, color: string, opacity?: number }[]>>}
 */
export const mapToGroupedData = (data, streamsMap) => {
  const res = [];
  for (let [date, { streams }] of Object.entries(data)) {
    const [year, month] = date.split(',');
    const item = { x: monthFormatter.format(new Date(`${year}-${month < 10 ? '0' : ''}${month}-01`)) };

    for (let [streamId, data] of Object.entries(streams)) {
      const { color, name } = streamsMap[streamId];
      item[name] = { value: data.count, color };
    }
    res.push(item);
  }

  // TODO: remove
  const item = { ...res[0] };
  for (let i = 1; i < 12; i++) {
    res.push({
      ...item,
      x: monthFormatter.format(new Date(`2024-${i+1 < 10 ? '0' : ''}${i+1}-01`)),
    });
  }

  return res;
};

/**
 * 
 * @param {Record<YearMonthDate, { streams: Record<StrStreamId, { validated_count: number, count: number}>}>} data
 * @param {Record<StrStreamId, { name: string, color: string }>} streamsMap
 * @returns {Array<Record<string, { name: string, value: number, color: string, opacity?: number }[]>>}
 */
export const mapToStackedGroupedData = (data, streamsMap) => {
  const res = [];
  for (let [date, datum] of Object.entries(data)) {
    const [year, month] = date.split(',');
    const item = { x: monthFormatter.format(new Date(`${year}-${month < 10 ? '0' : ''}${month}-01`)) };

    for (let [streamId, data] of Object.entries(datum.streams ?? {})) {
      const { color, name } = streamsMap[streamId];
      item[name] = [
        { name: 'Validated decisions', value: data.validated_count / 2, color },
        { name: 'All decisions', value: data.count, color, opacity: 0.5 },
      ];
    }

    for (let key of Object.keys(datum)) {
      let name, color;
      if (key === 'project_average' && datum[key]) {
        name = 'Average by the project';
        color = '#8C30F5';
      } else if (key === 'industry_average' && datum[key]) {
        name = 'Average by the industry'
        color = '#1DB5BE';
      } else {
        continue;
      }
      item[name] = [
        { name: 'Validated decisions', value: datum[key].validated_count / 2, color },
        { name: 'All decisions', value: datum[key].count, color, opacity: 0.5 },
      ];
    }

    res.push(item);
  }

  // TODO: remove
  const item = { ...res[0] };
  for (let i = 1; i < 12; i++) {
    res.push({
      ...item,
      x: monthFormatter.format(new Date(`2024-${i+1 < 10 ? '0' : ''}${i+1}-01`)),
    });
  }

  return res;
};
