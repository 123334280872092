import React, { useEffect, useState } from 'react';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { StackedGroupedBar } from '~/components/Charts/StackedGroupedBar';
import { useApi } from '~/hooks/useApi';

import { getFilters } from './utils';

export const DistributionQuantityDecisionsPerMemberChart = ({
  project,
  streams,
  streamsMap,
  height,
}) => {
  const apiRequest = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(() => []);

  const filters = getFilters(streams, null, project.id);
  console.log(filters)

  useEffect(() => {
    if (!project.id || !filters.hasFilters) {
      return;
    }

    setIsLoading(true);
    apiRequest({
      url: '/dashboard/distribution-quantity-business-decisions-per-team' + filters.query,
    }).then(({ success, body }) => {
      if (success) {
        const distribution = [];
        const {
          by_project: {
            all_decisions: allProject,
            validated_decisions: validatedProject,
          },
          by_industry: {
            all_decisions: allIndustry,
            validated_decisions: validatedIndustry,
          }
        } = body;

        for (let key of Object.keys(allProject)) {
          distribution.push({
            // key is 'bucket_0_5'
            x: key.split('_')[2] + '%',
            'This project': [
              { name: 'Validated decisions per team member on average', value: validatedProject[key].distribution / 2, color: '#8C30F5' },
              { name: 'All decisions per team member on average', value: allProject[key].distribution, color: '#8C30F5', opacity: 0.5 }
            ],
            'The industry': [
              { name: 'Validated decisions per team member on average', value: validatedIndustry[key].distribution / 2, color: '#1DB5BE' },
              { name: 'All decisions per team member on average', value: allIndustry[key].distribution, color: '#1DB5BE', opacity: 0.5 }
            ],
          });
        }
      
        setData(distribution);
      }

      setIsLoading(false);
    });
  }, [project.id, filters.streamIds.join(',')])

  return (
    <div>
      <ParentSize>
        {({ width }) => (
          <StackedGroupedBar
            data={data}
            width={width}
            height={height}
            isLoading={isLoading}
            formatY={(v) => `${v}%`}
            bottomAxis={{ left: 20, numTicks: 20 }}
          />
        )}
      </ParentSize>
    </div>
  );
};
